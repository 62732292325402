import ACTION_TYPES from '../constants/sessionConstants'

export const getSessionData = (session) => dispatch => {
  dispatch({
    type: ACTION_TYPES.GET_TOKEN,
    payload: session
  })
}

export const partnerId = (partnerId) => dispatch => {
  dispatch({
    type: ACTION_TYPES.PARTNER_ID,
    payload: partnerId
  })
}
