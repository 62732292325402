import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, List, ListItemText, Stack, 
    CircularProgress, Typography, DialogActions } from '@mui/material'
import PartnerItem from '../commons/PartnerItem';
import { LoadingButton } from '@mui/lab';
import api from '../../services/API'
import ALERT_ACTIONS from '../../redux/constants/alertConstants';
import { styled } from '@mui/system'
import SensieModalTitle from '../commons/SensieModalTitle';

const PatnerInteractiveItem = styled(PartnerItem, {
    shouldForwardProp: (prop) => prop !== "partnerId" && prop !== "selectedPartner"})
    (({theme, partnerId, selectedPartner}) => ({
        ...(selectedPartner && selectedPartner?.length > 0 && partnerId === selectedPartner && {
            backgroundColor:theme.palette.primary.main,
            color:'black',
            border:`1px solid ${theme.palette.primary.main}`
        })
    }))


const AssignPartnerModal = ({open, user, onClose, refresh}) => {
    const [dataPartners, setDataPartners] = useState([])
    const [loadingData, setLoadingData] = useState(false)
    const [assigning, setAssigning] = useState(false)
    const [selectedPartner, setSelectedPartner] = useState(null)
    const allPartners = useSelector(({PartnerReducer}) => PartnerReducer?.allPartners?.data?.partner )
    const dispatch = useDispatch()
    
    const fireAlert = (message, severity = 'success') => {
        dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
    }

    useEffect(()=>{
        if(allPartners?.length>0) setDataPartners([...allPartners])
    },[allPartners])
    
    const assignPartner = async () => {
        try {
            setAssigning(true)
            const { data } = await api.User().assingUserPartner(user.id, selectedPartner.id,localStorage.getItem('idToken'))
            if(data.status==='fail') throw new Error(data.message)
            refresh(selectedPartner)
            fireAlert(data.message)
        } catch (error) {
            fireAlert(error.message,'error')
        } finally {
            setAssigning(false)
        }
    }

    return(
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            onClose={()=>onClose(!assigning)}>
            <SensieModalTitle onClose={()=>onClose(!assigning)} title={`Assign partner to ${user.name}`}/>
            <DialogContent dividers>
                { loadingData ?
                <Stack alignItems="center" justifyContent="center" sx={{minHeight:'220px'}}>
                    <CircularProgress />
                </Stack>
                : <List>
                    {
                        dataPartners?.length > 0 &&
                        dataPartners.filter(p => !!p.isActive).map(partner=>(
                            <PatnerInteractiveItem
                                key={partner.id}
                                onClick={()=>setSelectedPartner(partner)}
                                partnerId={partner.id}
                                selectedPartner={selectedPartner?.id}>
                                <ListItemText
                                    primary={partner.name}
                                />
                            </PatnerInteractiveItem>
                        ))

                    }
                </List>
                }
            </DialogContent>
            <DialogActions>
                {selectedPartner && 
                    <LoadingButton loading={assigning} onClick={assignPartner}>Assign partner</LoadingButton>
                }
            </DialogActions>
        </Dialog>
    )
}

export default AssignPartnerModal