import React from 'react'

import { TableRow, TableContainer, Table, TablePagination, TableFooter } from '@mui/material'
import SensieTableHead from './SensieTableComponents/SensieTableHead'
import SensieTableBody from './SensieTableComponents/SensieTableBody'

const SensieTable = ({ ...props }) => {
  return (
    <>
      <TableContainer>
        <Table>
          <SensieTableHead heads={props.heads} {...props} />
          <SensieTableBody heads={props.heads} rows={props.data} menuItems={props.menuItems} {...props} />
          {
            !props.disablePagination &&
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={props.count}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onPageChange={props.handleChangePage}
                    onRowsPerPageChange={props.handleChangeRowsPerPage}
                    rowsPerPageOptions={props.tableDefaultsValues.pages}
                  />
                </TableRow>
              </TableFooter>
          }
        </Table>
      </TableContainer >
    </>
  )
}

export default SensieTable
