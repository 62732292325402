import { createStore, applyMiddleware } from "redux"
import { persistStore } from "redux-persist"
import { sessionService } from "redux-react-session"
import thunk from "redux-thunk"
import { reducers } from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

const middlewares = [
    thunk
]
// Comment out when debugging
// const store = createStore(reducers, applyMiddleware(thunk));

// Uncomment when debugging
const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(...middlewares))
)
sessionService.initSessionService(store, { driver: 'COOKIES' })
export const reduxStore = store
export const persistor = persistStore(reduxStore)