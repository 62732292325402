import ACTION_TYPES from "../../constants/userConstants";

const initialState = {
  users: {},
  userData: {},
  newUserData: {},
  userActive: {},
  usersByPartnerId: {},
  filterCriteria:null
}

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CREATE_USER:
      return {
        ...state
      }
    case ACTION_TYPES.FETCH_USERS:
      return {
        ...state,
        users: { ...action.payload }
      }
    case ACTION_TYPES.FETCH_USERS_ACTIVE:
      return {
        ...state,
        userActive: { ...action.payload }
      }
    case ACTION_TYPES.FETCH_USERS_BY_ID:
      return {
        ...state,
        userData: { ...action.payload }
      }
    case ACTION_TYPES.PUT_USER_DATA:
      return {
        ...state,
        newUserData: { ...action.payload }
      }
    case ACTION_TYPES.DELETE_USER_BY_ID:
      return {
        ...state,
        users: { ...action.payload }
      }
    case ACTION_TYPES.FETCH_USERS_BY_PARTNERID:
      return {
        ...state,
        usersByPartnerId: { ...action.payload }
      }
    case ACTION_TYPES.REMOVE_USER_FILTER:
        return {
            ...state,
            filterCriteria:[]
        }
    case ACTION_TYPES.SET_USER_FILTER_CRITERIA:
        return {
            ...state,
            filterCriteria: action.payload
        }
    default:
      return state
  }
}
