import React from 'react'
import { Routes, Route } from 'react-router-dom'
import NavigationRoutes from './PrivateRoutes'

import Home from '../pages/private/Home'
import Partner from '../pages/private/Partner'
import Gateway from '../pages/private/Gateway'
import Login from '../pages/public/Login'
import Users from '../pages/private/Users'
import NotFound from './NotFound'
import PasswordForgotten from '../pages/public/PasswordForgotten'
import ResetPassword from '../pages/public/ResetPassword'

const RoutersConfig = (props) => {
  return (
    <Routes>
      <Route path='/login' element={
        <NavigationRoutes.RedirectHome>
          <Login />
        </NavigationRoutes.RedirectHome>
      } />
      <Route path='/recovery' element={
        <NavigationRoutes.RedirectHome>
          <PasswordForgotten />
        </NavigationRoutes.RedirectHome>
      } />
      <Route path='/reset' element={
        <NavigationRoutes.RedirectHome>
          <ResetPassword />
        </NavigationRoutes.RedirectHome>
      } />

      <Route path='/' element={
        <NavigationRoutes.PrivateRoutes>
          <Home />
        </NavigationRoutes.PrivateRoutes>} />
      <Route path='/keys' element={
        <NavigationRoutes.PrivateRoutes>
          <Gateway />
        </NavigationRoutes.PrivateRoutes>} />
      <Route path='/partner' element={
        <NavigationRoutes.PrivateRoutes>
          <Partner />
        </NavigationRoutes.PrivateRoutes>} />
      <Route path='/admins' element={
        <NavigationRoutes.PrivateRoutes>
          <Users />
        </NavigationRoutes.PrivateRoutes>} />


      <Route path='*' element={<NotFound />} />

    </Routes>
  )
}

export default RoutersConfig
