import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PrivateRoutes = ({ children }) => {
  const isAuthenticated = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer.isAuthenticated, () => true)
  return isAuthenticated ? children : <Navigate to="/login" replace />
}

const RedirectHome = ({ children }) => {
  const isAuthenticated = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer.isAuthenticated, () => true)
  return isAuthenticated ? <Navigate to="/" replace /> : children
}

const NavigationRoutes = {
  PrivateRoutes,
  RedirectHome
}

export default NavigationRoutes
