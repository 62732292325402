import ACTION_TYPES from "../../constants/homeConstants";

const initialState = {
  chart: {},
  sensies: {},
  partners: {},
  path: {}
}

export const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CHART_DATA:
      return {
        ...state,
        chart: { ...action.payload }
      }
    case ACTION_TYPES.FETCH_SENSIES_CHART:
      return {
        ...state,
        sensies: { ...action.payload }
      }
    case ACTION_TYPES.FETCH_PARTNERS_CHART:
      return {
        ...state,
        partners: { ...action.payload }
      }
    case ACTION_TYPES.FETCH_REPORT:
      return {
        ...state,
        path: { ...action.payload }
      }
    default:
      return state
  }
}
