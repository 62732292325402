import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate  } from 'react-router-dom'
import NewPasswordView from '../../components/Login/NewPassword/NewPasswordView'
import ALERT_ACTIONS from '../../redux/constants/alertConstants'
const NewPassword = ({cognitoUser, returnAction}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const fireAlert = useCallback((message, severity = 'success') => {
        dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
    },[dispatch])

    const onSubmit = useCallback((password, cb) => {
        cognitoUser.completeNewPasswordChallenge(password, '', {
            onSuccess: (result) => {
                console.log("🚀 ~ file: NewPassword.js ~ line 19 ~ onSubmit ~ result", result)
                returnAction()
                fireAlert("The new password has been set")
            },
            onFailure: (err) => {
                fireAlert(err.message,"error")
                cb()
            }
        })
    },[fireAlert,navigate])
    return(
        <>
            <NewPasswordView onSubmit={onSubmit} returnAction={returnAction}/>
        </>
    )
}
export default NewPassword