import React, { Suspense } from 'react'
import * as ReactDOMClient from 'react-dom/client'
import "fontsource-roboto"
import reportWebVitals from './reportWebVitals'

import './App.css'
import { ThemeProvider } from '@mui/material'
import SensieTheme from './styles/SensieTheme'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import RoutersConfig from './navigation/RoutersConfig'
import { persistor, reduxStore } from './redux/reduxStore'
import Layout from './components/navigation/Layout'
import { CssBaseline } from '@mui/material'
import { Account } from './components/logSession/Account'

const App = () => {
  return (
    <Provider store={reduxStore}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={'test'}>
          <BrowserRouter>
            <ThemeProvider theme={SensieTheme}>
              <CssBaseline />
              <Account>
                <Layout>
                  <RoutersConfig />
                </Layout>
              </Account>
            </ThemeProvider>
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
  )
}

/* ReactDOMClient.createRoot(
  <ThemeProvider theme={SensieTheme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
); */

const rootElement = document.getElementById('root')
const root = ReactDOMClient.createRoot(rootElement)
root.render(<App callback={() => console.log("renderered")} />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
