
import LOGIN_TYPES from '../../constants/loginConstants'
const initialState = {
  newPassUser: null
}

export const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_TYPES.SAVE_USER:
      return {
        newPassUser: {...action.payload}
      }
    case LOGIN_TYPES.DISMISS_USER:
      return {...initialState}
    default:
      return state
  }
}
