import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

const AppAlert = () => {
    const dispatch = useDispatch()
    const alertState = useSelector(({ AlertReducer }) => AlertReducer)
    
    const closeAlert = () => {
        dispatch({ type: 'DISMISS_ALERT' })
    }

    return(
        <>
            { alertState?.open && alertState?.message?.length > 0 && 
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
                    open={alertState.open}
                    onClose={closeAlert}
                    autoHideDuration={3000}
                    >
                    <Alert onClose={closeAlert} severity={alertState.severity} sx={{ width: '100%', borderColor: 'white' }}>
                        {alertState.message}
                    </Alert>
                </Snackbar>
            }
        </>
    )
}
export default AppAlert