import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableLoading from '../commons/TableLoading';
import api from '../../services/API'
import ALERT_ACTIONS from '../../redux/constants/alertConstants';
import SensieTable from '../commons/SensieTable';
import PARTNERUSER_ACTIONS from '../../redux/constants/partnerUserConstants';

const TableHeads = [
    { id: 'name', label: 'Name', disableSorting: true },
    { id: 'lastname', label: 'Last name', disableSorting: true },
    { id: 'email', label: 'Email', disableSorting: true }
]

const AssignPartnerUser = ({partnerId}) => {
    const [quickElementsHide] = useState([])
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [adminUsers, setAdminUsers] = useState(null)
    const dispatch = useDispatch()

    const quickChange = useSelector(({PartnerUserReducer}) => PartnerUserReducer?.assignedUserId)

    const fireAlert = (message, severity = 'success') => {
        dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
    }
    const fetchAvailableUsers = async () => {
        try {
            setLoadingUsers(true)
            const { data } = await api.Partner().fetchActivePartnersByType('admin')
            if(data.status === 'fail') throw new Error(data.message)
            setAdminUsers([...data.data.admin])
        } catch (error) {
            fireAlert(error.message,'error')
        } finally {
            setLoadingUsers(false)
        }
    }

    useEffect(()=>{
        (fetchAvailableUsers)()
    },[])

    const assignUser = useCallback((item) => (
        api.User().assingUserPartner(item.id, partnerId, localStorage.getItem('idToken'))
    ),[partnerId])


    useEffect(()=>{
        if(quickChange){
            if(!quickElementsHide.includes(quickChange)) quickElementsHide.push(quickChange);
            dispatch({type:PARTNERUSER_ACTIONS.CLEAR_QUICK_DELETE_ASSIGNMENT})
            if(adminUsers?.length > 0 && quickElementsHide?.length > 0)
            {
                const newData = adminUsers.filter((e)=>(
                    !quickElementsHide.includes(e.id) 
                ))
                setAdminUsers([...newData])
            }
        }
    },[quickChange])

    return(
        <>
            { loadingUsers ?
                <TableLoading/> :
                adminUsers &&
                <>
                    <SensieTable
                        tableTitle='To assignate'
                        heads={TableHeads}
                        data={adminUsers}
                        toAssign
                        toAssignAction={assignUser}
                        disablePagination
                    />
                    
                </>
            }
        </>
    )
}
export default AssignPartnerUser
