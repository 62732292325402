import React, { useState, useCallback} from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { TableRow, TableCell, Chip, CircularProgress, Typography } from '@mui/material'
import ALERT_ACTIONS from '../../../../redux/constants/alertConstants'

import MenuItems from '../../MenuItems'

import DoneIcon from '@mui/icons-material/Done'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import PARTNERUSER_ACTIONS from '../../../../redux/constants/partnerUserConstants'

const SensieCell = ({label, item, cellAction = null}) => {
  const [processing, setProcessing] = useState(false)
  const dispatch = useDispatch()

  const fireAlert = useCallback((message, severity = 'success') => {
    dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
  },[dispatch])

  const action = async () => {
    try {
      setProcessing(true)
      if(!cellAction) throw new Error("An error has ocurred")
      const { data } = await cellAction(item)
      if(data.status==='fail') throw new Error(data.message)
      if(label==='Unassign'){
        dispatch({type:PARTNERUSER_ACTIONS.QUICK_DELETE, payload:item.id})
      } else {
        dispatch({type:PARTNERUSER_ACTIONS.QUICK_DELETE_ASSIGNMENT, payload:item.id})
      }
      fireAlert(data.message)
    } catch (error) {
      fireAlert(error.message)
    } finally {
      setProcessing(false)
    }
    
  }
  return(
    <TableCell align="center">
      <Chip size="small"
        label={label}
        color={label === "Unassign" ? "error" : "primary"}
        sx={{ cursor: 'pointer' }}
        disabled={processing}
        onClick={action}
        onDelete={action}
        deleteIcon={processing ? <CircularProgress size={16}/> : label === "Unassign" ? <HighlightOffIcon/> : <DoneIcon/> }
      />
    </TableCell>
  )
}
const SensieTableRow = ({ adminRole, heads, item, index, selectedIndex, ...props }) => {
  return (
    <TableRow >
      {
        heads.map((head, headIndex) => (
          <TableCell key={`head-${headIndex}`} sx={{maxWidth:'300px'}}>
            {
              head.id === 'createdAt'
                ? dayjs(item[head.id]).format('MM-DD-YYYY')
                :
                  <Typography sx={{
                    overflowWrap: 'break-word',
                    wordWrap:'break-word'
                                
                  }}>{item[head.id]}</Typography>
            } 
          </TableCell>
        ))
      }
      {
        props.toAssign &&
        <SensieCell 
          label="Assign"
          cellAction={props.toAssignAction}
          item={item}/>
      }
      {
        props.toUnassign &&
        <SensieCell 
          label="Unassign"
          cellAction={props.toUnassignAction}
          item={item}/>
      }
      {
        props.hasActiveOption &&
        <TableCell align="center">
          <Chip size="small"
            icon={
              <>
                {
                  props.isLoadingRow && index === selectedIndex &&
                  <CircularProgress color="inherit" sx={{ maxWidth: '15px', maxHeight: '15px', ml: 1 }} />
                }
              </>
            }
            disabled={!adminRole || props.isLoadingRow || (index === selectedIndex)}
            label={item.isActive ? 'Active' : 'Disable'}
            color={item.isActive ? 'primary' : 'error'}
            sx={{ cursor: 'pointer' }}
            onClick={() => props.updateStatus(item, index)}
            onDelete={() => props.updateStatus(item, index)}
            deleteIcon={item.isActive ? <DoneIcon /> : <HighlightOffIcon />}
          />

        </TableCell>
      }
      {
        props.hasActions && adminRole &&
        <TableCell padding='checkbox'>
          <MenuItems item={item} menuItems={props.menuItems} filterActions={props.filterActions}/>
        </TableCell>
      }
    </TableRow>
  )
}

export default SensieTableRow
