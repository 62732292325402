import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import DownloadIcon from '@mui/icons-material/Download'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined'
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'


import Chart from '../../components/Home/Chart'
import utils from '../../components/commons/utils/utils'
import SensiesModal from '../../components/Home/SensiesModal'
import * as homeActions from '../../redux/actions/HomeActions'
import PartnersChartModal from '../../components/Home/PartnersChartModal'
import PartnerVisualizer from '../../components/commons/PartnerVisualizer'
import { Grid, Box, Typography, Container, MenuItem, Select, FormControl, Skeleton, IconButton, CircularProgress, Tooltip } from '@mui/material'


const Home = ({ ...props }) => {
  const navigate = useNavigate()

  const [counter, setCounter] = useState({})
  const [partnerId, setPartnerId] = useState('')
  const [chartData, setChartData] = useState([])
  const [dataCounters, setDataCounters] = useState()
  const [filterDate, setFilterDate] = useState('24h-h')
  const [fetchingChart, setFetchingChart] = useState(true)
  const [openCounterModal, setOpenCounterModal] = useState('')
  const [noChartDataFound, setNoChartDataFound] = useState(false)
  const [reportLink, setReportLink] = useState('')
  const [loadingReport, setLoadingReport] = useState(false)

  const selectedPartner = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer.partnerId)
  const apiKeysByPartnerSelect = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer.partnerKeyInfo)
  const adminRole = useSelector(({ SessionPersistedReducer: { userData } }) =>
    userData.hasOwnProperty('cognito:groups') &&
    userData['cognito:groups'].some(x => x === "SuperAdmin"))

  useEffect(() => {
    if (!selectedPartner || selectedPartner?.id.length === 0) {
      setPartnerId('')
    } else {
      setPartnerId(selectedPartner.id)
    }
  }, [selectedPartner])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (adminRole) {
      setFetchingChart(true)
      props.homeActions.fetch_data_chart(filterDate, localStorage.getItem('idToken'), !selectedPartner ? '' : selectedPartner.id, onError, onSuccess)
    }
  }, [selectedPartner, filterDate])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (partnerId !== '' && adminRole === false) {
      setFetchingChart(true)
      props.homeActions.fetch_data_chart(filterDate, localStorage.getItem('idToken'), partnerId, onError, onSuccess)
    }
    if (reportLink !== '') {
      setReportLink('')
    }
  }, [partnerId, filterDate])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const object = []
    if (!dataCounters === false) {
      Object.keys(dataCounters).map((item) => {
        object.push({
          "name": utils.capitalizeFirstLetter(item), "value": dataCounters[item], "hasAction": item !== 'users' ? true : false
        })
        return setCounter(object.reverse())
      })
    }
  }, [dataCounters])// eslint-disable-line react-hooks/exhaustive-deps

  const onSuccess = (res) => {
    if (res.status === 'success') {
      setDataCounters(res?.data?.response?.total)
      setChartData(res?.data?.response?.items.reverse())
      if (res?.data?.response?.items?.some(x => x.data.length === 0)) {
        setNoChartDataFound(true)
        setFetchingChart(false)
      } else {
        setNoChartDataFound(false)
        setFetchingChart(false)
      }
    }
  }

  const onSuccessReport = (res) => {
    if (res.status === 'success') {
      setReportLink(res.data.url)
      setLoadingReport(false)
      window.open(res.data.url, '_blank')
    } else {
      setReportLink('')
      setLoadingReport(false)
    }
  }

  useEffect(() => {
    if (reportLink !== '') {
      const timer = setTimeout(() => {
        setReportLink('')
      }, 110000)

      return () => clearTimeout(timer)
    }
  }, [reportLink])

  const onError = (err) => {
    console.error(err)
    setLoadingReport(false)
  }

  const handleDayChange = (e) => {
    setFilterDate(e.target.value)
  }

  const handleOpenCounterModal = (e) => {
    setOpenCounterModal(e.name)
  }

  const handleClose = () => {
    setOpenCounterModal('')
  }

  const fetchReport = () => {
    setLoadingReport(true)
    props.homeActions.fetch_report_data(filterDate.split('-')[0], localStorage.getItem('idToken'), partnerId, onError, onSuccessReport)
  }

  const downloadFileAction = () => {
    if (reportLink === '') {
      fetchReport()
    } else {
      window.open(reportLink, '_blank')
    }
  }

  return (
    <>
      <SensiesModal open={openCounterModal} partnerId={partnerId} oldDate={filterDate} handleClose={handleClose} selectedPartner={selectedPartner} apiKeysByPartnerSelect={apiKeysByPartnerSelect} />
      <PartnersChartModal open={openCounterModal} handleClose={handleClose} />
      <Box p={2}>
        <Grid container justifyContent='space-between' alignItems="center" direction="row" mb={2}>
          <Grid item container justifyContent='flex-start' alignItems="center" xs={6}>
            <DashboardIcon fontSize='large' />
            <Typography ml={2} sx={{ alignSelf: 'center' }} variant='h5'>Dashboard</Typography>
          </Grid >
          <Grid item container justifyContent='flex-end' alignItems="center" xs={6} >
            <FormControl sx={{ minWidth: 120, mr: 1 }}>
              <Select
                size='small'
                value={filterDate}
                onChange={handleDayChange}
              >
                <MenuItem value={'24h-h'}>24 Hours</MenuItem>
                <MenuItem value={'3d-d'}>Last 3 days</MenuItem>
                <MenuItem value={'1w-d'}>Last week</MenuItem>
                <MenuItem value={'1M-d'}>Last month</MenuItem>
                <MenuItem value={'1y-M'}>Last year</MenuItem>
              </Select>
            </FormControl>
            <Tooltip title="Download report">
              <IconButton color="primary" component="span" disabled={loadingReport} onClick={() => downloadFileAction()} >
                {
                  loadingReport ?
                    <CircularProgress size={24} />
                    :
                    <DownloadIcon />
                }
              </IconButton>
            </Tooltip>
          </Grid >
        </Grid >
        <PartnerVisualizer />
        <Container fixed sx={{ minWidth: '100%', backgroundColor: '#242A2B', minHeight: '80vh', p: 3 }}>
          <Grid container justifyContent='space-between' direction="row" mb={2}>
            <Grid item>
              <Typography variant='h5'>Dashboard Overview</Typography>
            </Grid>
            <Box>
              <Grid container>
                {
                  fetchingChart ?
                    <>
                      <Skeleton animation="wave" height={140} width={400} sx={{ borderRadius: 2, p: 2, m: 1 }} />
                    </>
                    :
                    <>
                      {
                        counter?.length > 0 && counter?.map((item, i) => {
                          return (
                            <Grid key={i} onClick={() => handleOpenCounterModal(item)} item xs={12} sm={3}
                              sx={{
                                backgroundColor: '#121212', borderRadius: 2, p: 2, m: 1, minWidth: '120px', cursor: item.hasAction ? 'pointer' : '',
                                '&:hover': item.hasAction ? { p: 2, m: 1, boxShadow: '0px 0px 0px 1px #11E7BC inset' } : ''
                              }}>
                              <Typography variant='h6'>
                                {item.name}
                              </Typography>
                              <Grid container alignContent='center'>
                                <Typography variant='h6' sx={{ fontSize: 24, pr: 1 }}>
                                  {item.value}
                                </Typography>
                                {
                                  item.name === 'Partners' ? <DomainOutlinedIcon fontSize='large' color='primary' />
                                    : item.name === 'Users' ? <PersonOutlineOutlinedIcon fontSize='large' color='primary' />
                                      : item.name === 'Sensies' && <TrendingUpOutlinedIcon fontSize='large' color='primary' />
                                }
                              </Grid>
                            </Grid>
                          )
                        }
                        )
                      }
                    </>
                }
              </Grid>
            </Box>
          </Grid>
          {
            fetchingChart
              ?
              <Skeleton animation="wave" variant="rectangular" width="100%" height={600}>
                <div style={{ paddingTop: '57%', height: '600px' }} />
              </Skeleton>
              :
              <>
                {
                  noChartDataFound
                    ?
                    <Grid item container justifyContent='center' alignContent='center'
                      sx={{ border: '1px solid #B0B3B9', borderRadius: 4, minHeight: 600 }}>
                      <Typography variant='body1' sx={{ textAlign: 'center', color: '#B0B3B9' }}>
                        No data to show on dashboard
                      </Typography>
                    </Grid>
                    :
                    <Grid container  >
                      {
                        chartData?.length > 0 && chartData.map((chart, i) => {
                          return (
                            <Grid item key={i} xs={12}
                              lg={chart?.id === 'sensies' ? 12 :
                                chart?.id === 'partners' ? 6 :
                                  chart?.id === 'users' && (chartData.some(x => x.id === 'partners') === false) ? 12 : 6}   >
                              <Chart color={chart?.id === 'sensies' ? 'dark2' : chart?.id === 'partners' ? 'paired' : 'category10'} data={[chart]} />
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                }
              </>
          }
        </Container>
      </Box >
    </>
  )
}

const mapStateToProps = state => ({
  chartData: state.HomeReducer.chart,
  downloadPath: state.HomeReducer.path
})

const mapDispatchToProps = dispatch => {
  return {
    homeActions: bindActionCreators(homeActions, dispatch),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Home)
