import React from 'react'
import { Chip } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress';

const StatusIcon = ({isActive, updateStatus, updating}) => {

    const adminRole = useSelector(({SessionPersistedReducer:{userData}}) =>
        userData.hasOwnProperty('cognito:groups') &&
        userData['cognito:groups'].some(x => x === "SuperAdmin")
    )

    return(
        <>
            <Chip size="small"   
                disabled={!adminRole || !!updating}
                label={isActive ? 'Active' : 'Disabled'}
                color={isActive ? 'primary' : 'error'}
                sx={{ cursor: 'pointer' }}
                onClick={() => updateStatus()}
                onDelete={() => updateStatus()}
                deleteIcon={!!updating ? <CircularProgress 
                    size={13} sx={
                        { color: !!isActive ? 'black' : 'white'}}/> :
                    !!isActive ? <DoneIcon /> : <HighlightOffIcon />}
            />
        </>
    )
}
export default StatusIcon;