import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Dialog, DialogContent, DialogActions, Button, Slide, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton'

import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone'
import CONFIRMATION_ACTIONS from '../../redux/constants/confirmationConstants';
import ALERT_ACTIONS from '../../redux/constants/alertConstants';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const GlobalConfirmation = ({submitAction, refreshFn = null}) => {
    const [ processing, setProcessing ] = useState(false)
    const dispatch = useDispatch()
    const confirmation = useSelector(({ConfirmationReducer}) => ConfirmationReducer)
    
    const dismissConfirmation = () => {
        dispatch({type:CONFIRMATION_ACTIONS.DISMISS_CONFIRMATION})
    }
    const handleSubmit = async () => {
        setProcessing(true)
        try {
            const response = await submitAction(confirmation.record.id)
            if(!response) throw new Error("Fatal error // file: GlobalConfirmation.js ~ line 26 ~ handleSubmit ~ response")
            const message = response?.data?.message
            if(response?.data?.status === 'fail') throw new Error(message)
            dispatch({type:ALERT_ACTIONS.FIRE_ALERT, payload: { message: message }})
            if(!!refreshFn) refreshFn(confirmation.record.id)
        } catch (error) {
            dispatch({type:ALERT_ACTIONS.FIRE_ALERT, payload: { message: error.message, severity: 'error' }})
        } finally {
            setProcessing(false)
            dismissConfirmation()
        }
    }
    return(
        <>
            {confirmation.open && confirmation.message &&
                <Dialog
                    open={confirmation.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={dismissConfirmation}
                >
                    <DialogContent>
                        <Stack alignItems="center">
                            <ErrorTwoToneIcon sx={{ fontSize: 80, mb: 2 }} color='error'/>
                            {confirmation.message}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={dismissConfirmation}>{confirmation.cancelText}</Button>
                        <LoadingButton variant='contained' color='error'
                        loading={processing}
                        onClick={handleSubmit}>{confirmation.submitText}</LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
        
    )
}

export default GlobalConfirmation