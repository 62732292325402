const ACTION_TYPES = {
  CREATE_PARTNER: 'CREATE_PARTNER',
  FETCH_PARTNERS: 'FETCH_PARTNERS',
  FETCH_PARTNERS_BY_ID: 'FETCH_PARTNERS_BY_ID',
  FETCH_ALL_PARTNERS: 'FETCH_ALL_PARTNERS',
  FETCH_PARTNERS_BY_USERID: 'FETCH_PARTNERS_BY_USERID',
  PUT_PARTNER_DATA: 'PUT_PARTNER_DATA',
  DELETE_PARTNER_BY_ID: 'DELETE_PARTNER_BY_ID',
  UPDATE_STATUS_PARTNER: 'UPDATE_STATUS_PARTNER',
  TRIGGER_UPDATE: 'TRIGGER_UPDATE',
  REMOVE_FILTER: 'REMOVE_FILTER',
  SET_FILTER_CRITERIA: 'SET_FILTER_CRITERIA',
  TRIGGER_SELECTOR_UPDATE: 'TRIGGER_SELECTOR_UPDATE',
  DROP_SELECTOR_UPDATE: 'DROP_SELECTOR_UPDATE',
  CHANGE_SELECTOR_STATE: 'CHANGE_SELECTOR_STATE',
  CLEAN_ALL_PARTNERS: 'CLEAN_ALL_PARTNERS'
}

export default ACTION_TYPES
