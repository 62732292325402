import React from 'react'
import { Stack, Box, Typography } from '@mui/material'
import SearchOffIcon from '@mui/icons-material/SearchOff';

const NotFoundCard = ({text, action = null}) => {
    const handleClick = () => {
        if(action) action()
    }
    return(
        <Stack alignItems="center" justifyContent="center" sx={{padding:'4rem 0'}}>
            <Box onClick={handleClick} sx={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', 
                border: '1px solid #B0B3B9', borderRadius: 2, maxWidth: 400, padding:'4rem 6rem',
                gap: '1rem', cursor:`${!!action?'pointer':'default'}`
            }}>
                <SearchOffIcon sx={{ fontSize: 60, fill: '#B0B3B9' }}/>
                <Typography variant='body1' sx={{ textAlign: 'center', color: '#B0B3B9' }}>
                    {text}
                </Typography>
            </Box>
        </Stack>
    )
}

export default React.memo(NotFoundCard)