import axios from 'axios'

const config = (token) => {
  return {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
}

const partnerConfig = (token, partnerId) => (
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'x-partner-id': partnerId
    }
  }
)

const getBaseUrl = () => {
  return process.env.REACT_APP_API_URL
}

const Home = () => {
  return {
    fetchDataChart: (filterDate, token, partnerId) => axios.get(getBaseUrl() + `/graph?filterDate=${filterDate}`, partnerConfig(token, partnerId)),
    fetchSensiesChart: (filterType, accessGatewayId, filterDate, token, partnerId) => axios.get(getBaseUrl() + `/graph/sensie?filterType=${filterType}&accessGatewayId=${accessGatewayId}&filterDate=${filterDate}`, partnerConfig(token, partnerId)),
    fetchPartnerChart: (filterType, filterDate, token) => axios.get(getBaseUrl() + `/graph/partner?filterType=${filterType}&filterDate=${filterDate}`, config(token)),
    fetchReport: (filterDate, token, partnerId) => axios.get(getBaseUrl() + `/report?filterDate=${filterDate}`, partnerConfig(token, partnerId))
  }
}

const Partner = () => {
  return {
    createPartner: (values, token) => axios.post(getBaseUrl() + `/partner`, values, config(token)),
    fetchPartners: (token, limit, page, active, direction, column, filter = null) => axios.get(getBaseUrl() +
      `/partner?direction=${direction}&limit=${limit}&page=${page}&active=${active}&column=${column}
      ${filter?.length > 0 ? `&search=${filter}` : ''}`, config(token)),
    fetchPartnerById: (partnerId, token) => axios.get(getBaseUrl() + `/partner/${partnerId}`, config(token)),
    putPartnerById: (partnerId, values, token) => axios.put(getBaseUrl() + `/partner/${partnerId}`, values, config(token)),
    deletePartnerById: (partnerId, token) => axios.delete(getBaseUrl() + `/partner/${partnerId}`, config(token)),
    setStatusPartner: (partnerId, status, token) => axios.put(getBaseUrl() + `/partner/${partnerId}/status/${status}`, '', config(token)),

    fetchPartnersByUserId: (token, userId, limit, page, active) => axios.get(getBaseUrl() +
      `/user/${userId}/partner?limit=${limit}&page=${page}&active=${active}`, config(token)),
    fetchPartnersByUserIdV2: (userId, limit = null, page = null, active = null) => axios.get(getBaseUrl() +
      `/user/${userId}/partner?${limit?`limit=${limit}`:''}${page?`&page=${page}`:''}${active?`&active=${active}`:''}`, 
      config(localStorage.getItem('idToken'))),
    fetchAllPartners: (token) => axios.get(getBaseUrl() + `/filter?type=partner`, config(token)),
    fetchAllActivePartners: (token) => axios.get(getBaseUrl() + `/filter?active=true&type=partner`, config(token)),
    fetchActivePartnersByType: (partnerType) => axios.get(getBaseUrl() + `/filter?active=true&type=${partnerType}`, config(localStorage.getItem('idToken')))
  }
}

const User = () => {
  return {
    createUser: (values, token, partnerId) => axios.post(getBaseUrl() + `/user`, values, partnerConfig(token, partnerId)),
    fetchUsers: (token, limit, page, active, direction, column, filter) => axios.get(getBaseUrl() +
      `/user?direction=${direction}&limit=${limit}&page=${page}&active=${active}&column=${column}
      ${filter?.length > 0 ? `&search=${filter}` : ''}`, config(token)),
    fetchUserById: (userId, token) => axios.get(getBaseUrl() + `/user/${userId}`, config(token)),
    putUserById: (userId, values, token) => axios.put(getBaseUrl() + `/user/${userId}`, values, config(token)),
    deleteUserById: (userId, token) => axios.delete(getBaseUrl() + `/user/${userId}`, config(token)),
    setStatusUser: (userId, status, token) => axios.put(getBaseUrl() + `/user/${userId}/status/${status}`, '', config(token)),
    fetchUsersByPartnerId: (partnerId, limit, page, active, filter, direction, column) => axios.get(getBaseUrl() +
      `/partner/${partnerId}/user?limit=${limit}&page=${page}&active=${active}${!!direction ? `&direction=${direction}` : ''}${!!column ? `&column=${column}` : ''}
      ${filter?.length > 0 ? `&search=${filter}` : ''}`, config(localStorage.getItem('idToken'))),
    assingUserPartner: (userId, partnerId, token) => axios.post(getBaseUrl() + `/user/${userId}/partner?partnerId=${partnerId}`, '', config(token)),
    unassingUserPartner: (userId, partnerId, token) => axios.delete(getBaseUrl() + `/user/${userId}/partner?partnerId=${partnerId}`, config(token)),

    fetchAllActiveUsers: (token) => axios.get(getBaseUrl() + `/filter?active=true&type=user`, config(token))
  }
}

const Gateway = () => ({
  fetchAllGateways: (partnerId) => axios.get(`${getBaseUrl()}/gateway`, partnerConfig(localStorage.getItem('idToken'), partnerId)),
  createGateway: (partnerId, values) => axios.post(`${getBaseUrl()}/gateway`, values, partnerConfig(localStorage.getItem('idToken'), partnerId)),
  fetchToken: (partnerId, gatewayId) => axios.get(`${getBaseUrl()}/gateway/${gatewayId}`,
    partnerConfig(localStorage.getItem('idToken'), partnerId)),
  regenerateToken: (partnerId, gatewayId) => axios.put(`${getBaseUrl()}/gateway/${gatewayId}/regenerate`, '',
    partnerConfig(localStorage.getItem('idToken'), partnerId)),
  updateStatus: (partnerId, gatewayId, status) => axios.put(`${getBaseUrl()}/gateway/${gatewayId}/status/${status}`, '',
    partnerConfig(localStorage.getItem('idToken'), partnerId)),
  editGatewayById: (partnerId, gatewayId, values) => axios.put(`${getBaseUrl()}/gateway/${gatewayId}`, values, partnerConfig(localStorage.getItem('idToken'), partnerId)),
})

const api = {
  Partner,
  User,
  Gateway,
  Home
}

export default api
