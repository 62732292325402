const defaultPartnerData = {
  description: "",
  name: ""
}

const defaultUserData = {
  email: '',
  lastname: '',
  name: '',
  role: '',
  isActive: true
}
const defaultGatewayData = {
  name: '',
  limitUsers: '',
  limitRecords: '',
  expiration: null
}

const managementModal = {
  user: {
    email: "",
    id: "",
    isActive: false,
    lastname: "",
    name: ""
  },
  partner: {
    id: "",
    isActive: false,
    lastname: "",
    name: ""
  }
}

const partnerSelector = {
  partner: {
    email: "",
    id: "",
    isActive: false,
    lastname: "",
    name: ""
  }
}

const SEARCH_BY = {
  USER: 1,
  PARTNER: 2
}

const ROLE = {
  SUPERADMIN: "SuperAdmin",
  ADMIN: "Admin"
}

const Constants = {
  defaultPartnerData,
  defaultUserData,
  defaultGatewayData,
  managementModal,
  SEARCH_BY,
  partnerSelector,
  ROLE
}

export default Constants
