import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { Box, Grid, Typography } from '@mui/material'
import KeyIcon from '@mui/icons-material/Key'

import AddGatewayModal from '../../components/Gateway/AddGatewayModal'
import ListGateways from '../../components/Gateway/ListGateways'
import PartnerVisualizer from '../../components/commons/PartnerVisualizer'

const Gateway = () => {
    const [open, setOpen] = useState(false)
    const [selectedPartnerId, setSelectedPartnerId] = useState(null)

    const adminRole = useSelector(({SessionPersistedReducer:{userData}}) =>
      userData.hasOwnProperty('cognito:groups') &&
      userData['cognito:groups'].some(x => x === "SuperAdmin"))

    const selectedPartner = useSelector(({SessionPersistedReducer}) => SessionPersistedReducer.partnerId)

    useEffect(() => {
      if(selectedPartner && selectedPartner.id && selectedPartner.id.length > 0){
        setSelectedPartnerId(selectedPartner.id)
      }else{
        setSelectedPartnerId(null)
      }
    }, [selectedPartner])

    const openAddModal = useCallback(()=>{
      setOpen(true)
    },[setOpen])
    const closeAddModal = useCallback(()=>{
      setOpen(false)
    },[setOpen])
    
    return(
    <>
      {
        open &&
        <AddGatewayModal open={open} handleClose={closeAddModal} adminRole={adminRole} partnerId={selectedPartnerId}/>
      }
      <Box p={2}>
        <Grid container justifyContent='space-between' alignItems="middle" direction="row" mb={2}>
          <Grid item display='flex'>
            <KeyIcon fontSize='large' />
            <Typography ml={2} sx={{ alignSelf: 'center' }} variant='h6'>Keys</Typography>
          </Grid >
          {/* <Grid item>
            {
              adminRole && selectedPartnerId && !existingKeys &&
              <Button onClick={handleOpen} variant='contained'>
              Generate Keys
              </Button>
            }
          </Grid> */}
        </Grid>
        <PartnerVisualizer/>
      </Box> 
      <Box p={2}>
        <ListGateways handleOpenModal={openAddModal}/>
      </Box>
     
    </>
    )
}

export default Gateway;
  