import React from 'react';
import { Typography, Stack, Box, Button, Chip } from '@mui/material';
import { styled } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux';
import SessionConstants from '../../redux/constants/sessionConstants'
import ACTION_TYPES from '../../redux/constants/partnerConstants';

const PartnerBox = styled(Box)(({ active }) => ({
  marginBottom: '10px',
  backgroundColor: '#242A2B',
  borderRadius: '4px',
  boxSizing: 'border-box',
  padding: '1rem',
  cursor: `${active ? 'default' : 'pointer'}`

}))

const PartnerVisualizer = () => {
  const dispatch = useDispatch()
  const selectedPartner = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer?.partnerId)
  const adminRole = useSelector(({ SessionPersistedReducer }) =>
    SessionPersistedReducer?.userData?.['cognito:groups']?.some(x => x === "SuperAdmin"))

  const handleClick = () => {
    if (!selectedPartner) dispatch({ type: ACTION_TYPES.CHANGE_SELECTOR_STATE, payload: true })
  }

  return (
    <>
      <PartnerBox active={!!selectedPartner} onClick={handleClick}>
        {selectedPartner ?
          <>
            <Stack direction="row" justifyContent="space-between">
              <InfoBox label="Selected partner" text={selectedPartner.name} />

              <InfoBox label="Status" justifyCenter>
                <Chip label={selectedPartner.isActive ? 'Active' : 'Disabled'} color={selectedPartner.isActive ? "primary" : "error"} size="small" />
              </InfoBox>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems='flex-end'>
              <InfoBox label="Description" text={selectedPartner.description} />
              {
                adminRole &&
                <Button
                  color="error"
                  size="small"
                  onClick={() => dispatch({ type: SessionConstants.REMOVE_PARTNER })}
                >Cancel selection</Button>

              }
            </Stack>
          </>
          :
          <Typography sx={{ fontSize: '0.8rem', opacity: .5 }}>No partner selection made.</Typography>
        }
      </PartnerBox>
    </>
  )
}
const InfoBox = ({ children, label, text, justifyCenter }) => {
  return (
    <Stack alignItems={!justifyCenter ? 'flex-start' : 'center'}>
      <Typography sx={{ fontSize: '0.8rem', opacity: .5 }}>{label}:</Typography>
      {!!text && <Typography>{text}</Typography>}
      {!!children && children}
    </Stack>
  )
}
export default PartnerVisualizer
