import React, { useState } from 'react';

import { Stack, TextField, FormControl, Typography, Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import LogoColor from '../../../assets/image/sensie_color.png'

import LoadingButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/system';
const ResetForm = styled('form')({
    width:'100%'
}) 
const ForgotView = ({onSubmit}) => {
    const location = useLocation()
    const [processing, setProcessing] = useState(false)
    const [email, setEmail] = useState(location.state?.email || '')
    const navigate = useNavigate()
    const handleAction = (e) => {
        e.preventDefault()
        setProcessing(true)
        onSubmit(email, ()=>{
            setProcessing(false)
        })
    }
    return(
        <Stack alignItems="center" justifyContent="center" sx={{height:'100vh'}}>
            <Stack alignItems="center" sx={{ maxWidth: 400,width:'100%', p: 2, backgroundColor: '#151E1F', borderRadius: 2 }}>
                <img src={LogoColor} alt='Sensie logo' style={{ objectFit: 'contain', width: 150, height: 100 }} />
                <Stack alignItems="flex-start" sx={{width:'100%',p:2}}>
                    <Typography variant="h6">Forgot Password?</Typography>
                    <Typography variant="caption">
                        Don't worry! it happens. Please enter the address associated with your account.
                    </Typography>
                    <ResetForm onSubmit={handleAction} >
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <TextField value={email} type='email' onChange={(e) => setEmail(e.target.value)} label="Email"
                            inputProps={{style:{ WebkitBoxShadow: "0 0 0 1000px #151E1F inset"}}}/>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <LoadingButton loading={processing} disabled={email?.length===0} type="submit" variant='contained' >Submit</LoadingButton>
                        </FormControl>
                    </ResetForm>
                    <Button size="small" fullWidth sx={{alignSelf:'center', mt:2}} onClick={()=>navigate('../login')}>Back to login page</Button>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ForgotView