import PARTNERUSER_ACTIONS from "../../constants/partnerUserConstants"
const initialState = {
    open: false,
    partnerInfo: {},
    unassignedUserId: null,
    assignedUserId: null
  }

export const PartnerUserReducer = (state = initialState, action) => {
      switch (action.type) {
        case PARTNERUSER_ACTIONS.DISPLAY_PARTNERUSER_INFO:
            return {
                ...state,
                partnerInfo: {...action.payload},
                open: true
            }
        case PARTNERUSER_ACTIONS.DISMISS_INFO_DISPLAY_PARTNERUSER:
            return {
                ...state,
                open:false,
                partnerInfo:{}
            }
        case PARTNERUSER_ACTIONS.QUICK_DELETE:
            return {
                ...state,
                unassignedUserId: action.payload
            }
        case PARTNERUSER_ACTIONS.CLEAR_QUICK_DELETE:
            return {
                ...state,
                unassignedUserId: null
            }
        case PARTNERUSER_ACTIONS.QUICK_DELETE_ASSIGNMENT:
            return {
                ...state,
                assignedUserId: action.payload
            }
        case PARTNERUSER_ACTIONS.CLEAR_QUICK_DELETE_ASSIGNMENT:
            return {
                ...state,
                assignedUserId: null
            }
        default:
            return state
      }
      
  }