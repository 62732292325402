import React from 'react'

import { TableBody } from '@mui/material'
import SensieTableRow from './TableBodyParts/SensieTableRow'

const SensieTableBody = ({ ...props }) => {
  return (
    <TableBody>
      {         
        props.rows.map((item, index) => {
          return (
            <SensieTableRow key={index} {...props} menuItems={props.menuItems} heads={props.heads} item={item} index={index} selectedIndex={props.selectedIndex}
              filterActions={props.filterActions}/>
          )
        })
      }
    </TableBody>      
    
  )
}

export default SensieTableBody
