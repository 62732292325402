import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CognitoUser } from 'amazon-cognito-identity-js'

import ForgotView from '../../components/Login/Forgot/ForgotView'
import UserPool from '../../UserPool'
import ALERT_ACTIONS from '../../redux/constants/alertConstants'
const PasswordForgotten = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const emailPattern = useMemo(() => /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,[])
    const fireAlert = useCallback((message, severity = 'success') => {
        dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
    },[dispatch])

    const onSubmit = useCallback((email, cb) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: UserPool
        })
        try {
            const throwError = message => { throw new Error(message) }
            if(email?.length <= 0) throwError("Please enter your email.")
            if(!emailPattern.test(email)) throwError("Invalid email adress.")
            cognitoUser.forgotPassword({
                onSuccess: (data) => {
                    navigate(`../reset`, { state: { email: email }})
                    fireAlert('Code was sent to the user\'s email address.', 'info')
                },
                onFailure: (err) => {
                    fireAlert(err.message, 'error')
                    cb()
                }
              })
        } catch (error) {
            fireAlert(error.message, "error")
            cb()
        }
    },[fireAlert, emailPattern, navigate])

    return(
        <>
            <ForgotView onSubmit={onSubmit}/>
        </>
    )
}

export default PasswordForgotten