import React, { createContext } from 'react'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'

import Pool from '../../UserPool'

const AccountContext = createContext()
const Account = (props) => {
  const navigate = useNavigate()

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (user) {
        user.getSession((err, session,) => {
          if (err) {
            console.error('get session error:  ', err)
            logout()
          } else {
            resolve(session)
            /* localStorage.clear()
            const cognitoUserSession = session
            const yourIdToken = cognitoUserSession.getIdToken().jwtToken
            localStorage.setItem('idToken', yourIdToken)*/
            localStorage.setItem('expToken', JSON.stringify(jwt_decode(session.idToken.jwtToken).exp))
            localStorage.setItem('idToken', session.idToken.jwtToken)
            //console.log(session)
          }
        })
      } else {
        //console.log('user get session err:  ', reject)
        reject()
      }
    })
  }
  const tokenRefresh = async (props) => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (user) {
        user.getSession((err, session) => {
          const refresh_token = session.getRefreshToken()
          user.refreshSession(refresh_token, (refErr, refSession) => {
            if (refErr) {
              console.error('refreshToken err:  ', refErr)
            }
            else {
              resolve(session)
              //console.log(session)
              // localStorage.setItem('CognitoIdentityServiceProvider.5hcl1car9kp80262addr3kmgkp.229c7223-9a39-42b2-b27c-87f0e1929d5b.idToken', refSession.idToken.jwtToken)
              // localStorage.setItem('CognitoIdentityServiceProvider.5hcl1car9kp80262addr3kmgkp.229c7223-9a39-42b2-b27c-87f0e1929d5b.refreshToken', refSession.refreshToken.token)
              // localStorage.setItem('CognitoIdentityServiceProvider.5hcl1car9kp80262addr3kmgkp.229c7223-9a39-42b2-b27c-87f0e1929d5b.clockDrift', refSession.clockDrift)
              // localStorage.setItem('CognitoIdentityServiceProvider.5hcl1car9kp80262addr3kmgkp.229c7223-9a39-42b2-b27c-87f0e1929d5b.accessToken', refSession.accessToken.jwtToken)
              localStorage.setItem('expToken', JSON.stringify(jwt_decode(refSession.idToken.jwtToken).exp))
              localStorage.setItem('idToken', refSession.idToken.jwtToken)
            }
          })
          //console.log('refreshToken external err:  ', err)
        })
      } else {
        reject()
      }
    })
  }

  const logout = () => {
    const user = Pool.getCurrentUser()
    if (user) {
      user.signOut()
      navigate('/login')
    }
  }

  return (
    <AccountContext.Provider value={{ /* authenticate, */ getSession, logout, tokenRefresh }}>
      {props.children}
    </AccountContext.Provider>
  )
}

export { Account, AccountContext }
