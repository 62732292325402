import React, { useState, useEffect } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Typography, Modal, TextField, FormControl, Button, IconButton } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { ValidationError } from 'yup'
import { userScheme } from '../commons/Validations'
import Constants from '../commons/utils/Constants'
import * as userActions from '../../redux/actions/UserActions'
import ALERT_ACTIONS from '../../redux/constants/alertConstants'
import * as sessionActions from '../../redux/actions/SessionActions'

import CloseIcon from '@mui/icons-material/Close'
import GroupAddIcon from '@mui/icons-material/GroupAdd'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddUserModal = ({ ...props }) => {
  const [data, setData] = useState(Constants.defaultUserData)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()

  const selectedPartner = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer?.partnerId?.id)

  const handleChange = (e) => {
    const { name, value } = e.target
    const field = { [name]: value }
    setData({
      ...data,
      ...field
    })
  }



  useEffect(() => {
    if (props.isEdit)
      setData(props.item)
  }, [props.item]) // eslint-disable-line react-hooks/exhaustive-deps

  const fireAlert = (message, severity = 'success') => {
    dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
  }
  const onSubmit = async (e) => {
    e.preventDefault()
    setErrors({})
    setLoading(true)
    try {
      const dataToSend = { ...data, role: selectedPartner?.length > 0 ? 'admins' : 'SuperAdmin' }
      await userScheme.validate(dataToSend, { abortEarly: false })
      if (!props.isEdit)
        props.userActions.create_User(dataToSend, localStorage.getItem('idToken'), selectedPartner?.length > 0 ? selectedPartner : '', onError, onSuccessCreate)
      else
        props.userActions.put_users_by_id(props.item.id, dataToSend, localStorage.getItem('idToken'), onError, onSuccessEdit)
    } catch (error) {
      if (error instanceof ValidationError) {
        const object = {}
        error.inner.forEach(function (x) {
          if (x.path !== undefined) {
            object[x.path] = x.message
          }
        })
        setErrors(object)
      } else {
        fireAlert(error.message, "error")
      }
      setLoading(false)
    }
  }

  const onSuccessEdit = (response) => {
    setLoading(false)
    if (response.status === 'success') {
      props.editRehidrateFn(response?.data?.user)
      props.handleOpen()
      setData({ ...Constants.defaultUserData })
      setErrors({})
      fireAlert(response.message)
    } else if (response.status === 'fail') {
      fireAlert(response.message, 'error')
      setLoading(false)
      setErrors('fail')
    }
  }
  const onSuccessCreate = (response) => {
    setLoading(false)
    if (response.status === 'success') {
      setData({ ...Constants.defaultUserData })
      setErrors({})
      props.refresh()
      props.handleOpen()
      fireAlert(response.message)
    } else if (response.status === 'fail') {
      fireAlert(response.message, 'error')
      setErrors(response.data)
      setLoading(false)
    }

  }

  const onError = (err) => {
    setLoading(false)
    console.log(err)
  }

  const closeModal = () => {
    setData({ ...Constants.defaultUserData })
    setErrors({})
    props.handleOpen()
  }

  return (
    <>
      <Modal open={props.open} onClose={closeModal}>
        <Box sx={style} >
          <Grid container component="form" onSubmit={onSubmit} noValidate>
            <Grid item container justifyContent='space-between' alignItems='center' direction='row' mb={2}>
              <Grid item display='flex' >
                <GroupAddIcon fontSize='large' />
                <Typography ml={2} variant='h6'>{props.isEdit ? data.role : selectedPartner?.length > 0 ? 'Admin' : 'Super Admine'}</Typography>
              </Grid >
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <FormControl fullWidth variant="standard">
              <TextField label="Email" name="email"
                value={data.email} onChange={handleChange} sx={{ mb: 2 }}
                required disabled={props.isEdit}
                error={errors?.fields?.email || errors?.email}
                inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px #151E1F inset" } }}
                helperText={errors?.fields?.email?.errors[0] || errors?.email ||
                  (!props.isEdit && "This field cannot be changed once it has been saved.")}
              />
              <TextField
                label="Name" sx={{ mb: 2 }} name="name"
                value={data.name} onChange={handleChange}
                required
                inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px #151E1F inset" } }}
                error={((props.isEdit && errors === 'fail') || (errors.fields && errors.fields.name) || errors.name) && true}
                helperText={(errors.fields && errors.fields.name.errors) || errors.name}
              />
              <TextField
                label="Last Name" name="lastname" value={data.lastname}
                onChange={handleChange} sx={{ mb: 2 }}
                required
                inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px #151E1F inset" } }}
                error={((props.isEdit && errors === 'fail') || (errors.fields && errors.fields.lastname) || errors.lastname) && true}
                helperText={(errors.fields && errors.fields.lastname.errors) || errors.lastname}
              />
            </FormControl>
            <Grid item container justifyContent='flex-end'>
              <Grid item ml={2}>
                <Button onClick={closeModal} color='secondary' variant='contained' >Cancel</Button>
              </Grid>
              <Grid item ml={2}>
                <LoadingButton loading={loading} variant='contained' type="submit">{props.isEdit ? 'Edit' : 'Add'} {props.isEdit ? data.role : selectedPartner?.length > 0 ? ' Admin' : ' Super Admin'}</LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </ >
  )
}

const mapStateToProps = state => ({
  session: state.SessionPersistedReducer,
  users: state.UserReducer.users
})

const mapDispatchToProps = dispatch => {
  return {
    sessionActions: bindActionCreators(sessionActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AddUserModal)
