import { connect, useDispatch } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { bindActionCreators, compose } from 'redux'

import Constants from './utils/Constants'
import utils from './utils/utils'
import * as userActions from '../../redux/actions/UserActions'
import * as partnerActions from '../../redux/actions/PartnerAction'
import * as sessionActions from '../../redux/actions/SessionActions'

import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import SensieTable from '../../components/commons/SensieTable'
import { IconButton, Autocomplete, CircularProgress, Divider, Tab, Typography, TextField, Grid, Box, Drawer, useMediaQuery, Skeleton } from '@mui/material'
import ALERT_ACTIONS from '../../redux/constants/alertConstants'

const tableDefaultsValues = {
  page: 0,
  rowsPerPage: 5,
  pages: [5, 10, 25],
}

const TableHeads = [
  {
    id: 'name',
    label: 'Name',
    disableSorting: true
  }
]

const ManagementModal = ({ ...props }) => {
  const theme = useTheme()
  const [tabActive, setTabActive] = useState('1')
  // assigned table
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(5)
  const [active, setActive] = useState('')
  const [totalAssignItems, setTotalAssignItems] = useState(0)
  const [all, setAll] = useState(0)
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const [fetching, setFetching] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(Constants.managementModal)
  const [toAssigned, setToAssigned] = useState({})
  const [assigned, setAssigned] = useState({})
  const [resultItemsCount, setResultItemsCount] = useState(0)
  const [search_by, setSearch_by] = useState(0)
  const [firstItem, setFirstItem] = useState({})
  const [openPartnerList, setOpenPartnerList] = useState(false)
  const [openUserList, setOpenUserList] = useState(false)
  const [partnerOptions, setPartnerOptions] = useState([])
  const [userOptions, setUserOptions] = useState([])
  const loadingPartnerList = openPartnerList && partnerOptions.length === 0
  const loadingUserList = openUserList && userOptions.length === 0

  const dispatch = useDispatch()

  const fireAlert = (message, severity = 'success') => {
    dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
  }

  useEffect(() => {
    if (props.open) {
      setLoading(true)
      setFetching(true)
      props.partnerActions.fetch_all_partners(localStorage.getItem('idToken'), '', '', true, onError, onSuccess)
      props.userActions.fetch_users_active(localStorage.getItem('idToken'), '', '', true, onError, onSuccess)
      if (props.isDetails) {
        if (props.search === Constants.SEARCH_BY.USER) {
          setData({ ...data, user: props.item })
        }
        if (props.search === Constants.SEARCH_BY.PARTNER) {
          setData({ ...data, partner: props.item })
        }
        setFirstItem({ ...props.item })
        setSearch_by(props.search)
      }
    }
  }, [])

  useEffect(() => {
    if (data.partner.id !== '' && data.partner.id !== null) {
      setData({ ...data, user: Constants.managementModal.user })

    }
  }, [data.partner])

  useEffect(() => {
    if (data.user.id !== '' && data.user.id !== null) {
      setData({ ...data, partner: Constants.managementModal.partner })
    }
  }, [data.user])

  useEffect(() => {
    if (search_by === Constants.SEARCH_BY.USER) {
      props.partnerActions.fetch_partners_by_userId(localStorage.getItem('idToken'), data.user.id, limit, page + 1, '', onError, onSuccessSearch)
    }
    if (search_by === Constants.SEARCH_BY.PARTNER) {
      props.userActions.fetch_users_by_partnerid(data.partner.id, limit, page + 1, '', null, null,null, onError, onSuccessSearch)
    }
  }, [search_by])

  const onSuccessSearch = (response) => {
    if (search_by === Constants.SEARCH_BY.USER) {
      if (response && response.data) {
        setResultItemsCount(response?.data?.pagination?.totalItems)
        setAssigned(response.data.partners)
        setToAssigned(utils.differenceOfSet(props.partners.data.partner, response.data.partners))
      }
    }
    if (search_by === Constants.SEARCH_BY.PARTNER) {
      if (response && response.data) {
        setResultItemsCount(response?.data?.pagination?.totalItems)
        setAssigned(response.data.users)
        setToAssigned(utils.differenceOfSet(props.users.data.user, response.data.users))
      }
    }
    setTotalAssignItems(response?.data?.pagination?.totalItems)
    setAll(toAssigned.length)
    setLoading(false)
    setFetching(false)
    setSearch_by(0)
  }

  const onSearch = () => {
    if (data.partner.id !== '' || data.user.id !== '') {
      setLoading(true)
      setFetching(true)
      if (data.user !== undefined && data.user !== null && data.user.id !== '') {
        setSearch_by(Constants.SEARCH_BY.USER)
        setToAssigned(props.partners.data.partner)
        setFirstItem({ ...data.user })
      }
      if (data.partner !== undefined && data.partner !== null && data.partner.id !== '') {
        setSearch_by(Constants.SEARCH_BY.PARTNER)
        setToAssigned(props.users.data.user)
        setFirstItem({ ...data.partner })
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const field = { [name]: value }
    setData({
      ...data,
      ...field
    })
  }
  const handleAutocompleteChange = (value, name) => {
    const e = {
      target: {
        name: name,
        value: value
      }
    }
    handleChange(e)
  }

  const onError = (err) => {
    setLoading(false)
    setFetching(false)
  }

  const onSuccess = (response) => {
    setLoading(false)
    setFetching(false)
    setSearch_by(0)
  }

  const onAssing = (item) => {
    setLoading(true)
    setFetching(true)
    if (data.user !== undefined && data.user !== null && data.user.id !== '') {
      props.userActions.assing_user_partner(firstItem.id, item.id, localStorage.getItem('idToken'), onError, onSuccessAssingUnassing)
      // setSearch_by(Constants.SEARCH_BY.USER)
    } else if (data.partner !== undefined && data.partner !== null && data.partner.id !== '') {
      props.userActions.assing_user_partner(item.id, firstItem.id, localStorage.getItem('idToken'), onError, onSuccessAssingUnassing)
      // setSearch_by(Constants.SEARCH_BY.PARTNER)
    } else {
      fireAlert('Something went wrong! try again in a moment','error')
      setLoading(false)
      setFetching(false)
    }
  }

  const onUnAssing = (item) => {
    setLoading(true)
    setFetching(true)
    if (data.user !== undefined && data.user !== null && data.user.id !== '') {
      props.userActions.unassing_user_partner(firstItem.id, item.id, localStorage.getItem('idToken'), onError, onSuccessAssingUnassing)
    } else if (data.partner !== undefined && data.partner !== null && data.partner.id !== '') {
      props.userActions.unassing_user_partner(item.id, firstItem.id, localStorage.getItem('idToken'), onError, onSuccessAssingUnassing)
    } else {
      fireAlert('Something went wrong! try again in a moment','error')
      setLoading(false)
      setFetching(false)
    }
  }

  useEffect(() => {
    let active = true
    if (!loadingPartnerList) {
      return undefined
    }
    (async () => {
      if (active) {
        setPartnerOptions([...props.partners.data.partner])
      }
    })()
    return () => {
      active = false
    }
  }, [loadingPartnerList])

  useEffect(() => {
    if (!openPartnerList) {
      setPartnerOptions([])
    }
  }, [openPartnerList])

  useEffect(() => {
    let active = true
    if (!loadingUserList) {
      return undefined
    }
    (async () => {
      if (active) {
        setUserOptions([...props.users.data.user])
      }
    })()
    return () => {
      active = false
    }
  }, [loadingUserList])
  useEffect(() => {
    if (!setOpenUserList) {
      setPartnerOptions([])
    }
  }, [setOpenUserList])

  useEffect(() => {
    onSearch()
  }, [page, limit, active]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSuccessAssingUnassing = (response) => {
    fireAlert(response.message,`${response.status==='success'?'success':'error'}`)
    //setData({ ...Constants.managementModal })
    //setToAssigned({})
    //setAssigned({})
    onSearch()
    //setSearch_by(0)
    //setLoading(false)
    //setFetching(false)
  }

  const handleTabChange = (event, newValue) => {
    setTabActive(newValue)
  }

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value);
    setPage(0)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  return (
    <Drawer
      sx={{ zIndex: 1202 }}
      anchor={props.direction}
      open={props.open}
      onClose={() => props.setOpenConsole(false)}
    >
      <Box sx={{ width: matches ? '50vw' : '85vw' }}>
        <Grid p={2} pb={0} container justifyContent='space-between'>
          <Typography variant='h6'>
            Admin
          </Typography>
          <IconButton onClick={() => props.setOpenConsole(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container p={2} pb={0} spacing={1} alignItems='center' mb={3}>
          <Grid item>
            <Autocomplete
              name="partner"
              value={data.partner}
              sx={{ width: 300 }}
              open={openPartnerList}
              onOpen={() => {
                setOpenPartnerList(true)
              }}
              onClose={() => {
                setOpenPartnerList(false)
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name || ''}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )
              }}
              onChange={(event, newValue) => {
                if (!newValue)
                  newValue = Constants.managementModal.partner
                handleAutocompleteChange(newValue, 'partner')
              }}
              options={partnerOptions}
              loading={loadingPartnerList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Partners"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingPartnerList ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              name="user"
              value={data.user}
              sx={{ width: 300 }}
              open={openUserList}
              onOpen={() => {
                setOpenUserList(true)
              }}
              onClose={() => {
                setOpenUserList(false)
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id || ''}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name} {option.lastname}
                  </li>
                )
              }}
              onChange={(event, newValue) => {
                if (!newValue)
                  newValue = Constants.managementModal.user
                handleAutocompleteChange(newValue, 'user')
              }}
              options={userOptions}
              loading={loadingUserList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Admins"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingUserList ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <LoadingButton loading={loading} color="primary" variant='contained' onClick={onSearch}>Search</LoadingButton>
          </Grid>
        </Grid >
        <Divider />
        <Grid item xs={12}>
          <TabContext value={tabActive}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', pl: 2 }}>
              <TabList onChange={handleTabChange} >
                <Tab label="Assigned" value='1' />
                <Tab label="All" value='2' />
              </TabList>
            </Box>
            <TabPanel value='1'>
              {fetching ?
                <>
                  <Box >
                    <Typography variant='h1'>
                      <Skeleton animation="wave" />
                    </Typography>
                    <Skeleton animation="wave" height={75} />
                    <Skeleton animation="wave" height={75} />
                    <Skeleton animation="wave" height={75} />
                    <Skeleton animation="wave" height={75} />
                    <Skeleton animation="wave" height={75} />
                  </Box>
                  {/* <Grid container direction='row' sx={{ height: '100vh' }}>
                  <CircularProgress sx={{ m: 'auto', width: '100vw' }} />
                </Grid> */}
                </>
                :
                <>
                  {
                    assigned && assigned.length > 0 ?
                      <SensieTable
                        tableTitle='Assignated'
                        heads={TableHeads}
                        data={assigned}
                        tableDefaultsValues={tableDefaultsValues}
                        count={resultItemsCount}
                        rowsPerPage={limit}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        toUnassign
                        toUnassignAction={onUnAssing}
                      />
                      :
                      <>
                        <Grid item container justifyContent='center'>
                          Theres no assigned data
                        </Grid>
                      </>
                  }
                </>
              }
            </TabPanel>
            <TabPanel value='2'>
              {fetching ?
                <Box >
                  <Typography variant='h1'>
                    <Skeleton animation="wave" />
                  </Typography>
                  <Skeleton animation="wave" height={75} />
                  <Skeleton animation="wave" height={75} />
                  <Skeleton animation="wave" height={75} />
                  <Skeleton animation="wave" height={75} />
                  <Skeleton animation="wave" height={75} />
                </Box>
                :
                <>
                  {toAssigned && toAssigned.length > 0 ?
                    <SensieTable
                      tableTitle='To assignate'
                      heads={TableHeads}
                      data={toAssigned}
                      toAssign
                      toAssignAction={onAssing}
                      disablePagination
                    />
                    : <>
                      <Grid item container justifyContent='center'>
                        Theres no assigned data
                      </Grid>
                    </>
                  }
                </>
              }
            </TabPanel>
          </TabContext>
        </Grid>
      </Box >
    </Drawer >
  )
}

const mapStateToProps = state => ({
  session: state.SessionPersistedReducer,
  partners: state.PartnerReducer.allPartners,
  partnersByUserId: state.PartnerReducer.partnersByUserId,
  users: state.UserReducer.userActive,
  usersByPartnerId: state.UserReducer.usersByPartnerId
})

const mapDispatchToProps = dispatch => {
  return {
    sessionActions: bindActionCreators(sessionActions, dispatch),
    partnerActions: bindActionCreators(partnerActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ManagementModal)
