import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect, useCallback } from 'react'
import { Dialog, DialogTitle, DialogContent, List, ListItemText, Stack, Grid, CircularProgress, Container, Typography, Switch, FormGroup, FormControlLabel, Tooltip, IconButton } from '@mui/material'

import api from '../../services/API'
import PartnerItem from '../commons/PartnerItem'
import RefreshIcon from '@mui/icons-material/Refresh'
import ALERT_ACTIONS from '../../redux/constants/alertConstants'
import ACTION_TYPES from '../../redux/constants/partnerConstants'
import SessionConstants from '../../redux/constants/sessionConstants'

import CloseIcon from '@mui/icons-material/Close'

const PartnerSelector = ({ open, onOpen, onClose }) => {
  const dispatch = useDispatch()
  const [showActive, setShowActive] = useState(true)
  const [dataPartners, setDataPartners] = useState([])
  const [loadingData, setLoadingData] = useState(false)

  const selectedPartner = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer?.partnerId)
  const allPartners = useSelector(({ PartnerReducer }) => PartnerReducer?.allPartners?.data?.partner)
  const isAuthenticated = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer.isAuthenticated, () => true)
  const triggerUpdate = useSelector(({ PartnerReducer }) => PartnerReducer?.triggerUpdateSelect)
  const selectorOpen = useSelector(({ PartnerReducer }) => PartnerReducer?.outsideSelectorTrigger)

  const adminRole = useSelector(({ SessionPersistedReducer }) =>
    SessionPersistedReducer?.userData?.['cognito:groups']?.some(x => x === "SuperAdmin"))
  const updateList = useSelector(({ PartnerReducer }) => PartnerReducer?.partnerEditedSelector)
  const fetchGateways = useCallback(async (partnertId) => {
    try {
      dispatch({ type: SessionConstants.START_KEY_LOADING })
      const { data } = await api.Gateway().fetchAllGateways(partnertId)
      if (data?.status === 'fail') throw new Error(data.message)
      dispatch({ type: SessionConstants.KEY_INFO, payload: data.data })
    } catch (error) {
      if (error.message === 'Unauthorized user.') {
        dispatch({ type: SessionConstants.DROP_KEY_INFO_WITH_UA })
      }
      dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: error, severity: 'error' } })
    }
  }, [dispatch])

  const fetchOptions = useCallback(async (outsideCall = false) => {
    if ((isAuthenticated && allPartners && allPartners.length <= 0) || outsideCall) {
      setLoadingData(true)
      try {
        const { data } = await api.Partner().fetchAllPartners(localStorage.getItem('idToken'))
        dispatch({ type: ACTION_TYPES.FETCH_ALL_PARTNERS, payload: data })
        if (outsideCall) dispatch({ type: ACTION_TYPES.TRIGGER_UPDATE, payload: false })
        if (!(selectedPartner?.id?.length > 0)) dispatch({ type: SessionConstants.DROP_KEY_INFO })
        if (!(data?.data?.partner?.length > 1 && adminRole)) {
          dispatch({ type: SessionConstants.PARTNER_ID, payload: data?.data?.partner[0] })
          if (data?.data?.partner[0]?.id.length > 0) {
            fetchGateways(data.data.partner[0].id)
          }
        }
      } catch (error) {
        dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: error.message || 'An error ocurred', severity: 'error' } })
      } finally {
        setLoadingData(false)
      }
    }
  }, [isAuthenticated, allPartners, dispatch, adminRole, fetchGateways, selectedPartner])

  useEffect(() => {
    if (selectorOpen) {
      onOpen()
      dispatch({ type: ACTION_TYPES.CHANGE_SELECTOR_STATE, payload: false })
    }
  }, [selectorOpen])


  useEffect(() => {
    if (allPartners?.length > 0) setDataPartners([...allPartners])
  }, [allPartners])

  useEffect(() => {
    (fetchOptions)()
  }, [])

  useEffect(() => {
    if (triggerUpdate) fetchOptions(true)
  }, [triggerUpdate])

  useEffect(() => {
    if (updateList?.change && dataPartners?.length > 0) {
      let upPartners = dataPartners
      if (updateList.actionType === 'edit') {
        const foundIndex = upPartners.findIndex(p => p.id === updateList?.data[0]?.id)
        upPartners[foundIndex] = updateList?.data[0]
      } else if (updateList.actionType === 'delete') {
        upPartners = upPartners.filter((e) => (
          !(updateList.data.includes(e.id))
        ))
      }
      setDataPartners(upPartners)
      dispatch({ type: ACTION_TYPES.DROP_SELECTOR_UPDATE })
    }
  }, [updateList])

  const selectPartner = async (partner) => {
    dispatch({ type: SessionConstants.PARTNER_ID, payload: partner })
    onClose()
    fetchGateways(partner.id)
  }
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: 500 } }}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Grid item container direction="row" alignItems="center">
            <Typography variant="h6">
              Select partner
            </Typography>
            <Tooltip title="Reload partner list">
              <IconButton onClick={fetchOptions}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item container direction="row" alignItems="center" justifyContent="flex-end">
            <FormGroup>
              <FormControlLabel
                label={`${showActive ? "Active" : "Disabled"} partners`}
                control={
                  <Switch
                    checked={showActive}
                    onChange={(e) => {
                      setShowActive(!showActive)
                    }}
                  />
                } />
            </FormGroup>

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>

        </Stack>

      </DialogTitle>
      <DialogContent dividers>
        {loadingData ?
          <Stack alignItems="center" justifyContent="center" sx={{ minHeight: '220px' }}>
            <CircularProgress />
          </Stack>
          : <List>
            {
              dataPartners?.length > 0 &&
              dataPartners.filter(p => p.isActive === showActive).map(partner => (
                <PartnerItem
                  key={partner.id}
                  onClick={() => selectPartner(partner)}>
                  <ListItemText
                    primary={partner.name}
                  />
                </PartnerItem>
              ))

            }
          </List>
        }
      </DialogContent>
    </Dialog>
  )
}

export default PartnerSelector
