import React, { useEffect, useState, useCallback } from 'react'
import { Card, CardContent, Dialog, Grid, Typography, CircularProgress, Stack, Box, Button, DialogTitle } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

import UnassingUserPartner from './UnassignUserPartner'
import ENTITY_ACTIONS from '../../redux/constants/entityConstants'
import ALERT_ACTIONS from '../../redux/constants/alertConstants'
import api from '../../services/API'
import AssignPartnerModal from './AssignPartnerModal'
import SensieModalTitle from '../commons/SensieModalTitle'
const UserPartnerModal = () => {
    const dispatch = useDispatch()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [partnerInfo, setPartnerInfo] = useState(null)
    const [assignmentOpen,setAssignmentOpen] = useState(false)
    const [loadingPartnerInfo, setLoadingPartnerInfo] = useState(null)
    const open = useSelector(({EntityHelperReducer}) => EntityHelperReducer?.open)
    const userInfo = useSelector(({EntityHelperReducer}) => EntityHelperReducer?.userInfo)

    useEffect(()=>{
        if(open) setDialogOpen(true)
    },[open])

    const fetchUserPartner = async () => {
        setLoadingPartnerInfo(true)
        try{
            const { data: rspData } = await api.Partner().fetchPartnersByUserIdV2(userInfo.id, 5)
            if(rspData?.status === "fail") throw new Error(rspData.message)
            const partnerRes = rspData?.data?.partners?.[0]
            if(partnerRes) {setPartnerInfo({name:partnerRes?.name, id:partnerRes?.id, description:partnerRes?.description})}
        }catch(error){
            dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: error.message, severity: 'error' } })
        }finally{
            setLoadingPartnerInfo(false)
        }
    }
    useEffect(()=>{
        if(userInfo
        && Object.keys(userInfo).length > 0
        && Object.getPrototypeOf(userInfo) === Object.prototype) (fetchUserPartner)()
    },[userInfo])

    const refreshAssignment = useCallback((partner) => {
        setPartnerInfo({name:partner?.name, id:partner?.id, description:partner?.description})
        setAssignmentOpen(false)
    },[])

    const handleClose = () => {
        setDialogOpen(false)
        dispatch({type:ENTITY_ACTIONS.DISMISS_INFO_DISPLAY})
    }

    const refreshView = useCallback(() => {
        setPartnerInfo(null)
    },[])

    const closeAssignmentWindow = useCallback((actionable)=>{
        if(actionable) setAssignmentOpen(false)
    },[])

    return(
        <Dialog onClose={handleClose} open={dialogOpen}
            PaperProps={{style:{
                maxWidth:'700px', 
                width:'100%'
            }}}>
            <SensieModalTitle onClose={handleClose}>
                <Typography sx={{
                    overflowWrap: 'break-word',
                    wordWrap:'break-word'
                }}>Assigned partner to {userInfo?.name}</Typography>
            </SensieModalTitle>
            <Card sx={{ padding: '1rem' }}>
                <CardContent>
                    {
                        loadingPartnerInfo ? <Stack alignItems="center" justifyContent="center"><CircularProgress/></Stack> :
                        !!partnerInfo ?
                        <Grid container sx={{maxWidth:'700px',width:'100%', marginLeft:0}} spacing={1}>
                            <Grid item sm={8} xs={12}>
                                <Stack spacing={1}>
                                    <UserPartnerInfoBox label="Partner" text={partnerInfo?.name}/>
                                    <UserPartnerInfoBox label="Description" text={partnerInfo?.description}/>
                                </Stack>
                            </Grid>
                            <Grid item sm={4} xs={12} sx={{display:'flex'}} alignItems="center" justifyContent="flex-end">
                                <UnassingUserPartner 
                                    userId={userInfo?.id}
                                    partnerId={partnerInfo?.id}
                                    refresh={refreshView}/>
                            </Grid>
                        </Grid> :
                        <>
                            <AssignPartnerModal
                                user={userInfo}
                                open={assignmentOpen}
                                onClose={closeAssignmentWindow}
                                refresh={refreshAssignment}
                                />
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography>No partner has been assigned</Typography>
                                <Button onClick={()=>setAssignmentOpen(true)}>Assign partner</Button>
                            </Stack>
                        </>
                    }
                </CardContent>
            </Card>
        </Dialog>
    )
}
const UserPartnerInfoBox = ({label, text}) => (
    <Box>
        <Typography variant="body2" sx={{opacity:.6}}>
            {label}:
        </Typography>
        <Typography variant="body1">
            {text}
        </Typography>
    </Box>
)
export default UserPartnerModal