import React from 'react'
import { DialogTitle, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const SensieModalTitle = ({children, onClose, title = null}) => (
    <DialogTitle sx={{ m: 0, p: 2 }}>
        {title && <Typography variant='h6' sx={{
                    overflowWrap: 'break-word',
                    wordWrap:'break-word'
                }}>{title}</Typography>}
      {!title && 
        <>{children}</>}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
)

export default React.memo(SensieModalTitle)