import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Box, Container, Grid, Typography, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem, Stack } from '@mui/material'

import * as partnerActions from '../../redux/actions/PartnerAction'
import AddPartnerModal from '../../components/Partner/AddPartnerModal'
import SensieTable from '../../components/commons/SensieTable'
import ManagementModal from '../../components/commons/ManagementModal'
import api from '../../services/API'

import TableSearchBar from '../../components/commons/TableSearchBar'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import ACTION_TYPES from '../../redux/constants/partnerConstants'
import SessionConstants from '../../redux/constants/sessionConstants'
import Constants from '../../components/commons/utils/Constants'
import ALERT_ACTIONS from '../../redux/constants/alertConstants'
import GlobalConfirmation from '../../components/commons/GlobalConfirmation'
import CONFIRMATION_ACTIONS from '../../redux/constants/confirmationConstants'
import NotFoundCard from '../../components/commons/NotFoundCard'
import PartnerUserModal from '../../components/Partner/PartnerUserModal'
import PARTNERUSER_ACTIONS from '../../redux/constants/partnerUserConstants'

const TableHeads = [
  { id: 'name', label: 'Name', disableSorting: false },
  { id: 'description', label: 'Description', disableSorting: false },
  { id: 'createdAt', label: 'Creation date', disableSorting: false }
]

const Partner = ({ ...props }) => {
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [isDetails, setIsDetail] = useState(false)
  const [dataPartners, setDataPartners] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [isLoadingRow, setIsLoadingRow] = useState(false)
  const [fetchingPartners, setFetchingPartners] = useState(true)
  const [quickHiddenElements, setQuickHiddenElements] = useState([])
  const [itemData, setItemData] = useState(Constants.defaultPartnerData)


  const dispatch = useDispatch()

  //Table states
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [active, setActive] = useState('All')
  const [direction, setDirection] = useState('ASC')
  const [column, setColumn] = useState('name')

  const selectedPartner = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer?.partnerId?.id)
  const filterCriteria = useSelector(({ PartnerReducer }) => PartnerReducer.filterCriteria)

  const adminRole = props?.session?.userData?.['cognito:groups'].find(x => x === "SuperAdmin") === "SuperAdmin"

  const tableDefaultsValues = {
    page: 0,
    rowsPerPage: 15,
    pages: [15, 25, 50],
  }
  const fireAlert = useCallback((message, severity = 'success') => {
    dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
  }, [dispatch])
  const onError = useCallback((err) => {
    setFetchingPartners(false)
    dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: err.message, severity: 'error' } })
  }, [dispatch])

  const onSuccess = useCallback((response) => {
    setDataPartners(response.data.partners)
    setFetchingPartners(false)
    dispatch({ type: ACTION_TYPES.SET_FILTER_CRITERIA, payload: response?.data?.search?.criteria || [] })
  }, [dispatch])
  const fetchPartners = useCallback((filterText = null) => {
    setFetchingPartners(true)
    props.partnerActions.fetch_partners(localStorage.getItem('idToken'), limit, !!filterText ? 1 : page + 1,
      active === 'All' ? '' : active, direction, column, filterText, onError, onSuccess)
  }, [active, direction, column, page, limit, onError, onSuccess, props.partnerActions])

  const handleOpen = (item) => {
    let isEdit = item !== undefined && item.hasOwnProperty('name')
    if (isEdit) {
      setEdit(true)
      setItemData({ ...item })
    } else {
      setEdit(false)
    }
    setOpen(!open)
  }
  const handleOpenPartnerUser = useCallback((item) => {
    dispatch({
      type: PARTNERUSER_ACTIONS.DISPLAY_PARTNERUSER_INFO, payload: {
        id: item.id,
        name: item.name
      }
    })
  }, [])


  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value);
    setPage(0)
  }

  const handleActives = (e) => {
    let boolean = typeof e.target.value;
    boolean === 'boolean' ? setActive(e.target.value) : setActive('All')
    setLimit(limit);
    setPage(0)
  }

  const handleSortRequest = (cellId) => {
    const isAsc = column === cellId && direction === 'ASC'
    setColumn(cellId)
    if (cellId !== column) {
      if (cellId === 'isActive') {
        setDirection('DESC')
      } else {
        setDirection('ASC')
      }
    }
    else {
      //setDirection(isAsc ? 'DESC' : 'ASC')
      if (column === 'isActive') {
        setDirection(isAsc ? 'DESC' : 'ASC')
      } else {
        setDirection(isAsc ? 'DESC' : 'ASC')
      }
    }
  }

  const filterAction = useCallback((searchText) => {
    fetchPartners(searchText)
  }, [fetchPartners])
  const removeFilterAction = useCallback(() => {
    dispatch({ type: ACTION_TYPES.REMOVE_FILTER })
    fetchPartners()
  }, [dispatch, fetchPartners])

  useEffect(() => {
    fetchPartners()
  }, [page, limit, active, direction, column]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log(props.partnerStatusData)
    // if (dataPartners && selectedIndex > -1 && props.partnerStatusData.data) {
    if (dataPartners && selectedIndex > -1) {
      let tempPartners = dataPartners
      tempPartners[selectedIndex].isActive = !tempPartners[selectedIndex].isActive
      // tempPartners[selectedIndex].isActive = props.partnerStatusData.data.partner.isActive
      // switch (active) {
      //   case true:
      //     setDataPartners([...tempPartners].filter(x => x.id !== props.partnerStatusData.data.partner.id))
      //     break;
      //   case false:
      //     setDataPartners([...tempPartners].filter(x => x.id !== props.partnerStatusData.data.partner.id))
      //     break;
      //   default:
      //     tempPartners[selectedIndex].isActive = props.partnerStatusData.data.partner.isActive
      //     setDataPartners([...tempPartners])
      //     break;
      // }
      setDataPartners([...tempPartners])
    }
  }, [selectedIndex])


  // const onAssingToUser = (item) => {
  //   props.partnerActions.assing_user_partner(props.session.userId, item.id, localStorage.getItem('idToken'), onSuccess, onError)
  // }


  const rehidrateList = useCallback((partner) => {
    let upPartners = dataPartners
    const foundIndex = upPartners.findIndex(p => p.id === partner?.id)
    upPartners[foundIndex] = partner
    setDataPartners(upPartners)
    dispatch({
      type: ACTION_TYPES.TRIGGER_SELECTOR_UPDATE,
      payload: {
        partners: [partner]
      }
    })
  }, [dataPartners, dispatch])
  const onSuccessStatusUpdate = (response) => {
    setSelectedIndex(-1)
    setIsLoadingRow(false)
    dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: response.message, severity: response.status === 'fail' ? 'error' : 'success' } })
    const updatedPartner = response?.data?.partner
    if (response?.status === 'success' && updatedPartner) rehidrateList(updatedPartner);
    if (selectedPartner?.length > 0 && updatedPartner?.id === selectedPartner) {
      dispatch({ type: SessionConstants.PARTNER_ID, payload: updatedPartner })
    }
  }

  const handleOpenDeleted = useCallback((item) => {
    dispatch({ type: CONFIRMATION_ACTIONS.FIRE_CONFIRMATION, payload: { record: item } })
  }, [dispatch])

  const menuItems = [
    { title: 'Edit', action: handleOpen },
    { title: 'Delete', action: handleOpenDeleted },
    { title: 'View admins', action: handleOpenPartnerUser }
  ]
  useEffect(() => {
    if (quickHiddenElements.length > 0) {
      setDataPartners(dataPartners?.filter((e) => (
        !quickHiddenElements.includes(e.id)
      )))
      dispatch({
        type: ACTION_TYPES.TRIGGER_SELECTOR_UPDATE,
        payload: {
          partners: [...quickHiddenElements],
          actionType: 'delete'
        }
      })
      setQuickHiddenElements([])
    }
  }, [quickHiddenElements])

  const refreshData = useCallback((id) => {
    if (!quickHiddenElements.includes(id)) setQuickHiddenElements([...quickHiddenElements, id])
  }, [quickHiddenElements])
  // const updateStatus = (item, index) => {
  //   setSelectedIndex(index)
  //   setIsLoadingRow(true)
  //   props.partnerActions.set_status_partner(item.id, !item.isActive, localStorage.getItem('idToken'), onError, onSuccessStatusUpdate)
  // }
  const updateStatus = useCallback(async (item, index) => {
    try {
      setSelectedIndex(index)
      setIsLoadingRow(true)
      const { data } = await api.Partner().setStatusPartner(item?.id, !item.isActive, localStorage.getItem('idToken'))
      if (data.status === 'fail') throw new Error(data.message)
      fireAlert(data.message)
      const updatedPartner = data?.data?.partner
      dispatch({
        type: ACTION_TYPES.TRIGGER_SELECTOR_UPDATE,
        payload: {
          partners: [updatedPartner]
        }
      })
      if (selectedPartner?.length > 0 && updatedPartner?.id === selectedPartner) {
        dispatch({ type: SessionConstants.PARTNER_ID, payload: updatedPartner })
      }
      if (!!item?.id && 'boolean' === typeof active) {
        refreshData(item.id)
      }
    } catch (error) {
      onError(error)
      fireAlert(error.message, "error")
    } finally {
      setSelectedIndex(-1)
      setIsLoadingRow(false)
    }
  }, [fireAlert, refreshData, active])
  const deleteItem = useCallback((record) => (
    api.Partner().deletePartnerById(record, localStorage.getItem('idToken'))
  ), [])

  const editRehidrateFn = useCallback((partner) => {
    rehidrateList(partner)
  }, [rehidrateList])

  return (
    <>
      <PartnerUserModal />
      <GlobalConfirmation submitAction={deleteItem} refreshFn={refreshData} />
      <AddPartnerModal open={open} editRehidrate={editRehidrateFn} handleOpen={handleOpen}
        actions={props} refresh={fetchPartners} item={itemData} isEdit={edit} />
      <Box p={2}>
        <Grid container justifyContent='space-between' alignItems="middle" direction="row" mb={2}>
          <Grid item display='flex'>
            <DomainAddIcon fontSize='large' />
            <Typography ml={2} sx={{ alignSelf: 'center' }} variant='h6'>Partners</Typography>
          </Grid >
          <Grid item>
            {
              adminRole &&
              <Button onClick={handleOpen} variant='contained'>
                Add partner
              </Button>
            }
          </Grid>
        </Grid>
        <Container fixed sx={{ minWidth: '100%', backgroundColor: '#242A2B', minHeight: '80vh', p: 2 }}>
          <Grid container justifyContent='space-between' alignItems='flex-start' direction="row" mb={2}>
            <Grid item>
              <Typography variant='h5'>
                {active === true ? 'Showing active partners' :
                  active === false ? 'Showing disabled partners' :
                    'Showing all partners'
                }
              </Typography>
            </Grid>
            <Grid item>
              <Stack direction='row' spacing={2}>
                <TableSearchBar label="partners" submitAction={filterAction}
                  removeAction={removeFilterAction}
                  filterCriteria={filterCriteria} />
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel >Status</InputLabel>
                  <Select
                    label='Status'
                    value={active}
                    onChange={handleActives}>
                    <MenuItem value={true}>Active only</MenuItem>
                    <MenuItem value={false}>Disabled only</MenuItem>
                    <MenuItem value={'All'}>All</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
          {
            fetchingPartners ?
              <Stack direction="row" sx={{ width: '100%', minHeight: '350px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress sx={{ m: 'auto', width: '100vw' }} />
              </Stack>
              :
              props?.partners?.data?.partners && props?.partners?.data?.pagination?.totalItems > 0 ?
                <>
                  <SensieTable
                    menuItems={menuItems}
                    heads={TableHeads}
                    // data={props.partners.data.partners}
                    data={dataPartners}
                    page={props?.partners?.data?.pagination?.currentPage - 1}
                    count={props?.partners?.data?.pagination?.totalItems}
                    rowsPerPage={limit}
                    tableDefaultsValues={tableDefaultsValues}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    hasActiveOption
                    hasActions
                    updateStatus={updateStatus}
                    adminRole={adminRole}
                    isLoadingRow={isLoadingRow}
                    selectedIndex={selectedIndex}
                    handleSortRequest={handleSortRequest}
                    direction={direction}
                    column={column}
                  />
                </>
                :
                <>
                  <Grid container >
                    <Grid item sx={{ minWidth: '100%' }}>
                      {
                        !adminRole ?
                          <NotFoundCard text={`No partners found`} /> :
                          'boolean' === typeof active ?
                            <NotFoundCard text={`No ${active ? 'active' : 'inactive'} partners found`} /> :
                            filterCriteria?.length > 0 ?
                              <NotFoundCard text="No partners found matching the search criteria" /> :
                              <NotFoundCard text="No partners found, click here to add a partner" action={handleOpen} />
                      }
                    </Grid>
                  </Grid>
                </>
          }
        </Container >

      </Box>
    </>
  )
}

const mapStateToProps = state => ({
  session: state.SessionPersistedReducer,
  partners: state.PartnerReducer.partners,
  partnerStatusData: state.PartnerReducer.partnerStatusData
  //partnersTotal: state.PartnerReducer.partnersTotal
  /* partnerData: state.PartnerReducer.partnerData,
  users: state.UserReducer.users,
  userData: state.UserReducer.userData,*/
})

const mapDispatchToProps = dispatch => {
  return {
    /*  reducerLogout: () => dispatch({ type: 'LOGOUT' }), */
    // sessionActions: bindActionCreators(sessionActions, dispatch),
    partnerActions: bindActionCreators(partnerActions, dispatch)
    //userActions: bindActionCreators(userActions, dispatch),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Partner)
