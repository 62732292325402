import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch  } from 'react-redux';
import ResetView from '../../components/Login/Reset/ResetView';
import { CognitoUser } from 'amazon-cognito-identity-js'
import ALERT_ACTIONS from '../../redux/constants/alertConstants';
import UserPool from '../../UserPool'
const ResetPassword = () => {
    // const { email } = useParams()
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const email = location.state?.email || null
    const fireAlert = useCallback((message, severity = 'success') => {
        dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
    },[dispatch])
    
    const onSubmit = useCallback((formData, cb) => {
        const {email:emailData, password, verificationCode} = formData
        const cognitoUser = new CognitoUser({
            Username: emailData,
            Pool: UserPool
        })
        cognitoUser.confirmPassword(verificationCode, password, {
            onFailure(err) {
                fireAlert(err.message, 'error')
                cb()
            },
            onSuccess(data) {
                fireAlert('Password was successfully changed.')
                navigate('../../login')
            },
        })
        
    },[fireAlert, email, navigate])
    return(
        <>
            <ResetView onSubmit={onSubmit} email={email}/>
        </>
    )
}

export default ResetPassword