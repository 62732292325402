import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect, useContext } from 'react'

import { Box, Toolbar, useMediaQuery, Grid } from '@mui/material'
import { AccountContext } from '../../components/logSession/Account'
import SessionConstants from '../../redux/constants/sessionConstants'
import { DrawerHeader, AppBar } from '../../styles/Components/navigation/LayoutStyle'

import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import logo from '../../assets/image/sensie.png'
import PartnerSelector from '../Partner/PartnerSelector'
import LayoutDrawer from './LayoutDrawer'
import AppAlert from '../commons/AppAlert'
import UserMenu from './UserMenu'

const Layout = ({ children, ...props }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [now, setNow] = useState()
  const [openPartnerSelector, setOpenPartnerSelector] = useState(false)
  const session = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer)
  const { logout, tokenRefresh } = useContext(AccountContext)

  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' })
    logout()
    dispatch({ type: 'CLEAN_ALL_PARTNERS' })
    dispatch({ type: SessionConstants.REMOVE_PARTNER })
    localStorage.clear()
    navigate('/login')
  }

  useEffect(() => {
    setNow(dayjs())
  }, [])// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const dateNow = setInterval(() => {
      setNow(dayjs())
    }, 600000)
    return () => clearInterval(dateNow)
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    var expToken = localStorage.getItem('expToken')
    var dateExpToken = dayjs(expToken * 1000)
    var diffTime = dateExpToken.diff(now, 'minute')
    if (expToken) {
      if (diffTime <= 0) {
        handleLogout()
      } else if (diffTime < 11) {
        tokenRefresh()
      }
    }
  }, [now])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOpen(matches === true)
  }, [matches])// eslint-disable-line react-hooks/exhaustive-deps
  const openPartnerSelectFn = () => {
    setOpenPartnerSelector(true)
  }
  const cancelPartnerSelectFn = () => {
    setOpenPartnerSelector(false)
  }

  return (
    <>
      <AppAlert />
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {
          session?.isAuthenticated &&
          <>
            <PartnerSelector open={openPartnerSelector} onOpen={openPartnerSelectFn} onClose={cancelPartnerSelectFn} />
            <AppBar position="fixed" open={open}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    ...(open && { display: 'none' }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Grid item sx={{ flexGrow: 1 }}>
                  <img src={logo} alt='Sensie' style={{ objectFit: 'contain', width: 80, textAlign: 'center' }} />
                </Grid>
                <>
                  {
                    matches &&
                    <>
                      {session?.userData?.name + ' ' + session?.userData?.family_name}
                    </>
                  }
                  <UserMenu />
                </>
              </Toolbar>
            </AppBar>
            <LayoutDrawer
              open={open}
              handleDrawerOpen={handleDrawerOpen}
              openPartnerSelectFn={openPartnerSelectFn}
            />
          </>
        }
        <Box component="main" sx={{ flexGrow: 1, }}>
          {
            session?.isAuthenticated &&
            <DrawerHeader />
          }
          {children}
        </Box>
      </Box>
    </>
  )
}

export default Layout
