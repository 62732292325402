import React, { useEffect } from 'react'
import { Card, FormControl, Radio, Grid, RadioGroup, Typography } from '@mui/material'
import utils from '../../components/commons/utils/utils'
import { PropaneRounded } from '@mui/icons-material'

const ChartCards = ({ counters, ...props }) => {
  useEffect(() => {
    props.setGroupRadio(props.group)
  }, [])
  return (
    <Grid item sx={{ width: '20%', p: '5px', mt: 2, cursor: 'pointer', }}>
      <Card sx={{ p: 2, minHeight: '100%' }} onClick={() => (props.setChartToShow(props.id))}>
        <Grid container direction='row' alignItems='center' justifyContent='space-between'>
          <Grid item container justifyContent='space-between' alignItems='center' sx={{ mb: 3 }}>
            <Typography variant='h6' >
              {utils.camelToTitleCase(props.id)}
            </Typography>
            <FormControl>
              <RadioGroup value={props.chartToShow}>
                <Radio value={props.id} />
              </RadioGroup>
            </FormControl>
          </Grid>
          {
            counters?.map((x, i) => {
              return (
                <Grid container justifyContent='space-between' alignItems='center' key={i}>
                  <Typography variant='subtitle1'>
                    {utils.capitalizeFirstLetter(x.id)}
                  </Typography>
                  <Typography color='primary' variant='h6' ml={2}>
                    {x.value}
                  </Typography>
                </Grid>
              )
            })
          }
        </Grid>
      </Card >
    </Grid >
  )
}

export default ChartCards
