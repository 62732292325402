import React, { useState } from 'react'
import { InputAdornment, IconButton, OutlinedInput, InputLabel } from '@mui/material'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'


const PasswordInput = ({label, identifier, value, refreshValue, showPass=false}) => {
    const [visible, setVisible] = useState(false)
    
    return (
        <>
            <InputLabel htmlFor={identifier}>{label}</InputLabel>
            <OutlinedInput id={identifier} autoComplete='off' label={label} type={visible ? 'text' : 'password'} value={value} 
                onChange={(e) => refreshValue(e.target.value)}
                endAdornment={
                    showPass &&
                    <InputAdornment position="end">
                    <IconButton
                        onClick={() => setVisible(!visible)}
                        onMouseDown={(e) => e.preventDefault}
                        edge="end"
                    >
                        {
                        visible ? <VisibilityOff /> : <Visibility />
                        }
                    </IconButton>
                    </InputAdornment>
                }
            />
        </>
    )
}

export default PasswordInput