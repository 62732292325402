import { ListItem } from '@mui/material'
import { styled } from '@mui/system'
const PartnerItem = styled(ListItem)(({theme})=>({
    cursor:'pointer',
    boxSizing:'border-box',
    borderRadius:'8px',
    border:'1px solid transparent',
    ':hover':{
        border:`1px solid ${theme.palette.primary.main}`
    }
}))

export default PartnerItem