import { Grid, Stack, Typography } from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import { useTheme } from '@mui/material/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
const DefaultValues = {
    length:false,
    upper:false,
    lower:false,
    number:false,
    special:false,
    validCode:false
}

const HelperType = ({children, active}) => {
    const theme = useTheme()
    return(
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={.5}>
            { active ? <CheckCircleIcon fontSize='8px' color='primary'/> : <CircleOutlinedIcon fontSize='8px' sx={{opacity:.25}}/> }
            <Typography variant="caption" sx={{
                lineHeight:'1',
                color: active ? theme.palette.primary.main : 'white',
                opacity: active ? 1 : .25,
                transition:'all .4s ease-in-out'
            }}>{children}</Typography>
        </Stack>
    )
}

const PassHelper = ({data, updateValidation}) => {
    const [passHints, setPassHints] = useState(DefaultValues)
    const lowerReg = new RegExp('[a-z]','g')
    const upperReg = new RegExp('[A-Z]','g')
    const numberReg = new RegExp('[0-9]','g')
    const specialReg = new RegExp(/[$&+,:;=?@#|'<>.*()%!"\-_\\/[\]^`{}~]/,'g')
    const validateVerificationCode = data.hasOwnProperty('verificationCode')
    useEffect(() => {
        setPassHints({
            validCode: !validateVerificationCode || (data.verificationCode.length === 6 && !isNaN(data.verificationCode)),
            length: data.password.length >= 8,
            lower: lowerReg.test(data.password),
            upper: upperReg.test(data.password),
            number: numberReg.test(data.password),
            special: specialReg.test(data.password),
            match: data.password.length > 0 && data.password === data.passwordConfirmation
        })
    },[data])
    
    useEffect(() => {
        passHints['validCode'] = !validateVerificationCode ? true : passHints['validCode']
        updateValidation(Object.values(passHints).every(vs=>vs===true))
    },[passHints])

    const ruleNames = { length:'8 characters minimum',number:'One number',
        lower:'One lowercase character',upper:'One uppercase character',
        special:'One special character', match:'Both passwords match',
        validCode:'Valid code'
    }

    return(
        <Grid sx={{p:1}} container>
            { 
                Object.keys(passHints).map(k => {
                    if(!validateVerificationCode && k ==='validCode') { return(<Fragment key={k}></Fragment>)}
                    else {return(
                        <Grid item key={k} sm={6} sx={{ display:'flex',alignItems: 'flex-start', marginBottom:'5px' }}>
                            <HelperType active={passHints[k]}>{ruleNames[k]}</HelperType>
                        </Grid>
                    )}
                })
            }
        </Grid>
    )
}

export default PassHelper