import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { bindActionCreators, compose } from 'redux'

import Chart from './Chart'
import ChartCards from '../Home/ChartCards'
import utils from '../../components/commons/utils/utils'
import * as homeActions from '../../redux/actions/HomeActions'
import { Button, TextField, Dialog, DialogActions, DialogContent, FormControl, IconButton, Select, MenuItem, DialogTitle, InputLabel, Grid, Autocomplete, CircularProgress, Typography, Skeleton, Divider } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

const SensiesModal = ({ open, partnerId, ...props }) => {

  const [data, setData] = useState([])
  const [fetching, setFetching] = useState()
  const [loading, setLoading] = useState(true)
  const [firstOpen, setFirstOpen] = useState(true)
  const [filterDate, setFilterDate] = useState('')
  const [groupRadio, setGroupRadio] = useState('all')
  const [gatewaysList, setGatewaysList] = useState([])
  const [noDataToShow, setNoDataToShow] = useState(false)
  const [chartToShow, setChartToShow] = useState('sessionType')
  const [gatewayId, setGatewayId] = useState({ label: '', id: '', filterType: '' })

  useEffect(() => {
    if (open === 'Sensies') {
      if (firstOpen) {
        setFilterDate(props.oldDate)
        setFirstOpen(false)
      }
    } else {
      setFirstOpen(true)
      setFilterDate('')
    }
    const object = [{ label: 'Both', id: '', filterType: 'apiKeys' }]
    if (props?.apiKeysByPartnerSelect?.keys?.length > 0) {
      props?.apiKeysByPartnerSelect?.keys?.map((x) => {
        object.push({ label: x.mode, id: x.id, filterType: 'apiKeys' })
        return setGatewaysList(object)
      })
    }
  }, [open])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open === 'Sensies' && filterDate !== '') {
      setFetching(true)
      props.homeActions.fetch_sensies_data_chart(gatewayId.filterType, gatewayId.id, filterDate, localStorage.getItem('idToken'), partnerId, onError, onSuccess)
    } else {

      setChartToShow('')
      setNoDataToShow(false)
      setLoading(true)
      setGatewayId({ label: '', id: '', filterType: '' })
    }

  }, [open, filterDate, gatewayId])// eslint-disable-line react-hooks/exhaustive-deps

  const onSuccess = (res) => {
    if (res.status === 'success') {
      setNoDataToShow(false)
      if (res.data.response.length === 0) {
        setNoDataToShow(true)
      }
      if (res?.data?.response?.length > 0) {
        setData(res?.data?.response)
      }
      setLoading(false)
      setFetching(false)
    }
  }
  const onError = (err) => {
    console.error('On Error:  ', err)
    setLoading(false)
    setNoDataToShow(true)
  }

  const handleDayChange = (e) => {
    setFilterDate(e.target.value)
  }

  return (
    <>
      <Dialog fullWidth maxWidth={'xl'} open={open === 'Sensies'} onClose={props.handleClose}>
        <DialogTitle>
          <Grid container justifyContent='space-between'>
            <Grid item >
              <Typography variant='h4'>
                Sensies
              </Typography>
              <Typography variant='subtitle1' color='primary'>
                {props?.selectedPartner?.name}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems='center' direction='row'>
                {
                  fetching &&
                  <Grid item >
                    <CircularProgress color="primary" size={25} />
                  </Grid>
                }
                <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                  <InputLabel >
                    Date range
                  </InputLabel>
                  <Select
                    label='Date range'
                    defaultValue={props.oldDate}
                    value={filterDate}
                    onChange={handleDayChange}
                  >
                    <MenuItem value={'24h-h'}>24 Hours</MenuItem>
                    <MenuItem value={'3d-d'}>Last 3 days</MenuItem>
                    <MenuItem value={'1w-d'}>Last week</MenuItem>
                    <MenuItem value={'1M-d'}>Last month</MenuItem>
                    <MenuItem value={'1y-M'}>Last year</MenuItem>
                  </Select>
                </FormControl>
                {
                  props?.apiKeysByPartnerSelect?.keys?.length > 0 && gatewaysList.length > 0 &&
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Autocomplete
                      name="gatewayId"
                      value={gatewayId}
                      onChange={(event, newValue) => {
                        if (!newValue)
                          newValue = { label: '', id: '', filterType: '' }
                        setGatewayId(newValue)
                      }}
                      sx={{ width: 200 }}
                      isOptionEqualToValue={(option, value) => { return option.id === value.id }}
                      getOptionLabel={(option) => option.label}
                      defaultValue={{ label: '', id: '', filterType: '' }}
                      options={gatewaysList}
                      loading={loading}
                      size='small'
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Api Key"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                }
                <IconButton onClick={props.handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {
            noDataToShow ? <Grid container justifyContent="center" alignItems='center' sx={{ height: 400, border: '1px solid #B0B3B9', borderRadius: 4, }}>
              <Typography variant='h6'>No data to show</Typography>
            </Grid> :
              <>
                {
                  loading ? <Skeleton animation="wave" variant="rectangular" width={'100%'} height={400} /> : <>
                    {
                      data?.length > 0 && data?.map((chartsData, i) => {
                        const obj = []
                        Object.keys(chartsData).map((item) => {
                          obj.push({ "name": item, "value": chartsData[item] })
                          return obj
                        })
                        const totals = []
                        Object.keys(obj[0].value).map((item) => {
                          totals.push({ "id": item, "value": obj[0].value[item] })
                          return totals
                        })
                        if (chartToShow === '') {
                          setChartToShow(totals[0].id)
                        }
                        if (chartToShow === 'clients' && !totals?.some(x => x.id === 'clients'))
                          setChartToShow(totals[0].id)
                        const group = obj[0]?.name
                        return (
                          <React.Fragment key={i}>
                            <Divider >
                              {utils.capitalizeFirstLetter(obj[0].name)}
                            </Divider>
                            <Grid container justifyContent="center">
                              {
                                totals?.length > 0 && totals?.map((item, i) => {
                                  const counters = []
                                  Object.keys(item.value.total).map((c) => {
                                    counters.push({ "id": c, "value": item.value.total[c] })
                                    return counters
                                  })
                                  return (
                                    <ChartCards key={i} setChartToShow={setChartToShow} chartToShow={chartToShow} group={group} setGroupRadio={setGroupRadio} groupRadio={groupRadio} id={item.id} counters={counters} />
                                  )
                                })
                              }
                            </Grid>
                            {
                              totals?.map((item, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    {item.id === chartToShow &&
                                      < Chart data={item.value.items} color='category10' />
                                    }
                                  </React.Fragment>
                                )
                              })
                            }
                          </React.Fragment>
                        )
                      })
                    }
                  </>
                }
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => ({
  sensiesData: state.HomeReducer.sensies
})

const mapDispatchToProps = dispatch => {
  return {
    homeActions: bindActionCreators(homeActions, dispatch),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SensiesModal)
