import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LogoColor from '../../../assets/image/sensie_color.png'
import { FormControl, Button, Typography, TextField, Stack} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import PassHelper from '../PassHelper'
import PasswordInput from '../PasswordInput'
import { styled } from '@mui/system';

const ResetForm = styled('form')({
    width:'100%'
})

const ResetView = ({email, onSubmit}) => {
    const [processing, setProcessing] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const [formData, setFormData] = useState({
        email:email||'',
        verificationCode:'',
        password:'',
        passwordConfirmation:''
    })
    const navigate = useNavigate()
    const handleAction = (e) => {
        e.preventDefault()
        setProcessing(true)
        onSubmit(formData, ()=>{
            setProcessing(false)
        })
    }
    return(
        <Stack alignItems="center" justifyContent="center" sx={{height:'100vh'}}>
            <Stack alignItems="flex-start" sx={{ maxWidth: 400,width:'100%', p: 3, backgroundColor: '#151E1F', borderRadius: 2 }} spacing={1}>
                <img src={LogoColor} alt='Sensie logo' style={{ alignSelf:'center', objectFit: 'contain', width: 150, height: 80 }} />
                <Typography variant="body2">
                    Create a new password:
                </Typography>
                <ResetForm onSubmit={handleAction}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <TextField label='Email' value={formData.email} 
                        onChange={(e) => setFormData({...formData, email: e.target.value})} />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <TextField label='Verification code' inputProps={{maxLength:6}} autoComplete='off' value={formData.verificationCode} 
                        onChange={(e) => setFormData({...formData, verificationCode: e.target.value})} />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <PasswordInput label="Password"
                            identifier="password"
                            value={formData.password}
                            refreshValue={(value) => setFormData({...formData, password: value})}
                            showPass/>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <PasswordInput label="Confirm password"
                            identifier="passwordConfirmation"
                            value={formData.passwordConfirmation}
                            refreshValue={(value) => setFormData({...formData, passwordConfirmation: value})}/>
                    </FormControl>
                    <PassHelper data={formData} updateValidation={setCanSubmit}/>
                    <FormControl fullWidth >
                         <LoadingButton loading={processing} sx={{ mt: 2 }} type="submit"
                         disabled={!canSubmit} variant='contained' >Change password</LoadingButton>
                     </FormControl>
                </ResetForm>
                <Button size="small" fullWidth sx={{alignSelf:'center'}} onClick={()=>navigate('../login')}>Back to login page</Button>
            </Stack>
        </Stack>
    )
}

export default ResetView