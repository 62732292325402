import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { bindActionCreators, compose } from 'redux'

import Constants from './../../components/commons/utils/Constants'
import * as userActions from '../../redux/actions/UserActions'

import GroupAddIcon from '@mui/icons-material/GroupAdd'

import AddUserModal from '../../components/Users/AddUserModal'
import SensieTable from '../../components/commons/SensieTable'
import { Box, Container, Grid, Typography,  FormControl, InputLabel, Select, MenuItem, Stack, Button, CircularProgress } from '@mui/material'
import ManagementModal from '../../components/commons/ManagementModal'
import TableSearchBar from '../../components/commons/TableSearchBar'
import ALERT_ACTIONS from '../../redux/constants/alertConstants'
import ACTION_TYPES from '../../redux/constants/userConstants'
import PartnerVisualizer from '../../components/commons/PartnerVisualizer'
import CONFIRMATION_ACTIONS from '../../redux/constants/confirmationConstants'
import GlobalConfirmation from '../../components/commons/GlobalConfirmation'
import api from '../../services/API'
import NotFoundCard from '../../components/commons/NotFoundCard'
import ENTITY_ACTIONS from '../../redux/constants/entityConstants'
import UserPartnerModal from '../../components/Users/UserPartnerModal'

const Users = ({ ...props }) => {
  const navigate = useNavigate()
  const adminRole = useSelector(({ SessionPersistedReducer }) =>
    SessionPersistedReducer?.userData?.['cognito:groups']?.some(x => x === "SuperAdmin"))

  const selectedPartner = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer.partnerId)
  const newPartnerSelection = useSelector(({SessionPersistedReducer}) => SessionPersistedReducer?.partnerKeyInfo?.newSelection)

  const filterCriteria = useSelector(({UserReducer}) => UserReducer.filterCriteria)

  const [quickHiddenElements, setQuickHiddenElements] = useState([])
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [partners, setPartners] = useState({})
  const [fetching, setFetching] = useState(false)
  const [isDetails, setIsDetail] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [isLoadingRow, setIsLoadingRow] = useState(false)
  const [itemData, setItemData] = useState(Constants.defaultUserData)

  //Table states
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [active, setActive] = useState('All')
  const [direction, setDirection] = useState('ASC')
  const [column, setColumn] = useState('name')

  const dispatch = useDispatch()

  const tableDefaultsValues = {
    page: 0,
    rowsPerPage: 15,
    pages: [15, 25, 50],
  }

  const TableHeads = [
    { id: 'name', label: 'Name', disableSorting: false },
    { id: 'lastname', label: 'Last Name', disableSorting: false },
    { id: 'email', label: 'Email', disableSorting: false },
    { id: 'role', label: 'Role', disableSorting: false },
    { id: 'createdAt', label: 'Creation date', disableSorting: false}
  ]

  const handleOpen = (item) => {
    let isEdit = item !== undefined && item.hasOwnProperty('name')
    if (isEdit) {
      setEdit(true)
      setItemData({ ...item })
    } else {
      setEdit(false)
    }
    setOpen(!open)
  }

  const openPartnerInfo = (item) => {
    dispatch({type:ENTITY_ACTIONS.DISPLAY_ENTITY_INFO, payload:{
      id:item.id,
      name:item.name
    }})
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value)
    setPage(0)
  }

  const handleActives = (e) => {
    let boolean = typeof e.target.value
    boolean === 'boolean' ? setActive(e.target.value) : setActive('All')
    setLimit(limit);
    setPage(0)
  }

  const handleSortRequest = (cellId) => {
    const isAsc = column === cellId && direction === 'ASC'
    setColumn(cellId)
    if (cellId !== column) {
      if (cellId === 'isActive') {
        setDirection('DESC')
      } else {
        setDirection('ASC')
      }
    }
    else {
      //setDirection(isAsc ? 'DESC' : 'ASC')
      if (column === 'isActive') {
        setDirection(isAsc ? 'DESC' : 'ASC')
      } else {
        setDirection(isAsc ? 'DESC' : 'ASC')
      }
    }
  }

  useEffect(() => {
    if (adminRole === false) {
      navigate('/')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filterText = filterCriteria?.length > 0 ? `${filterCriteria.join(' ')}` : null
    fetchUsers(filterText)
  }, [page, limit, active, direction, column, selectedPartner]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (partners.users && selectedIndex > -1) {
      //console.log(selectedIndex)
      let tempUsers = partners.users
      //console.log(tempUsers)
      tempUsers[selectedIndex].isActive = !tempUsers[selectedIndex].isActive
      setPartners({ ...partners, users: [...tempUsers] })
    }
  }, [selectedIndex])
  
  const fireAlert = useCallback((message, severity = 'success') => {
    dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
  },[dispatch])

  const onError = (err) => {
    setFetching(false)
  }
  const onSuccess = (response) => {
    setPartners(response.data)
    setFetching(false)
    if(response?.data?.search?.criteria?.length > 0){
      dispatch({type:ACTION_TYPES.SET_USER_FILTER_CRITERIA, payload: response?.data?.search?.criteria})
    }
    //setShowTable(true)
  }

  const fetchUsers = useCallback((filterText = null) => {
    setFetching(true)
    
    if (!selectedPartner || selectedPartner?.id.length <= 0) {
      props.userActions.fetch_users(localStorage.getItem('idToken'),
        limit, !!filterText ? 1 : page + 1, active === 'All' ? '' : active, direction, column, filterText, onError, onSuccess)
    } else {
      let intPage = page + 1
      if(newPartnerSelection) {
        intPage = 1
        dispatch({type:"DISMISS_NEW_SELECTION"})
      }
      props.userActions.fetch_users_by_partnerid(selectedPartner.id, limit, !!filterText ? 1 : intPage,
        active === 'All' ? '' : active, filterText,direction, column, onError, onSuccess)
    }
  },[selectedPartner, limit, page, active, direction, column, onError, onSuccess, props.userActions,dispatch, newPartnerSelection])

  const filterAction = useCallback((searchText)=>{
    fetchUsers(searchText)
  },[fetchUsers])

  const removeFilterAction = useCallback(()=>{
    dispatch({type:ACTION_TYPES.REMOVE_USER_FILTER})
    fetchUsers()
  },[dispatch, fetchUsers])

  const handleOpenDeleted = useCallback((item) => {
    dispatch({type:CONFIRMATION_ACTIONS.FIRE_CONFIRMATION, payload: { record: item }})
  },[dispatch])

  const menuItems = [
    { title: 'Edit', action: handleOpen },
    { title: 'Delete', action: handleOpenDeleted },
    { title: 'View partner', action: openPartnerInfo, filter:true }
  ]
  useEffect(()=>{
    if(quickHiddenElements.length > 0) {
      setPartners({...partners, users: partners?.users?.filter((u)=>(
        !quickHiddenElements.includes(u.id)

        ))})
      setQuickHiddenElements([])
    }
  },[quickHiddenElements])

  const rehidrateList = useCallback((user) => {
    let upUsers = partners.users
    const foundIndex = upUsers.findIndex(p=>p.id === user?.id)
    upUsers[foundIndex] = user
    setPartners({...partners, users: [...upUsers]})
  },[partners])

  const editRehidrateFn = useCallback((user)=>{
    if(!!user) rehidrateList(user)
  },[rehidrateList])

  const refreshData = useCallback((id) => {
    if(!quickHiddenElements.includes(id)) setQuickHiddenElements([...quickHiddenElements, id])
  },[quickHiddenElements])
  
  const updateStatus = useCallback(async (item, index) => {
    try {
      setSelectedIndex(index)
      setIsLoadingRow(true)
      const { data } = await api.User().setStatusUser(item?.id, !item.isActive, localStorage.getItem('idToken'))
      if(data.status==='fail') throw new Error(data.message)
      setSelectedIndex(-1)
      setIsLoadingRow(false)
      fireAlert(data.message)
      if(!!item?.id && 'boolean' === typeof active) {
        refreshData(item.id)
      }
    } catch (error) {
      setSelectedIndex(-1)
      setIsLoadingRow(false)
      fireAlert(error.message, "error")
    } 
  },[fireAlert, refreshData, active])

  const deleteItem = useCallback((record)=>(
    api.User().deleteUserById(record, localStorage.getItem('idToken'))
  ),[])
  const filterActions = useCallback((rowData) => (rowData.role !== "SuperAdmin"),[])
  
  return (
    <>
      <UserPartnerModal/>
      <GlobalConfirmation submitAction={deleteItem} refreshFn={refreshData}/>
      <AddUserModal selectedPartner={selectedPartner} editRehidrateFn={editRehidrateFn} open={open}
        handleOpen={handleOpen} actions={props} refresh={fetchUsers} item={itemData} isEdit={edit} />

      <Box p={2}>
        <Grid container justifyContent='space-between' alignItems="middle" direction="row" mb={2}>
          <Grid item display='flex'>
            <GroupAddIcon fontSize='large' />
            <Typography ml={2} sx={{ alignSelf: 'center' }} variant='h6'>Admins</Typography>
          </Grid >
          <Grid item>
            {
              adminRole &&
              <Button onClick={handleOpen} variant='contained'>
                Add {!!selectedPartner?"admin":"superadmin"}
              </Button>
            }
          </Grid>
        </Grid>
            <PartnerVisualizer/>
            <Container fixed sx={{ minWidth: '100%', backgroundColor: '#242A2B', minHeight: '80vh', p: 2 }}>
              <Grid container justifyContent='space-between' direction="row" mb={2}>
                <Grid item xs={5}>
                  <Typography variant='h6'>
                    {`Showing ${active === true ? 'active' : active === false ? 'disabled' : 'all'}
                    admins${selectedPartner?.id?.length > 0 ? ` of ${selectedPartner.name}.` : '.'}`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <TableSearchBar label="admins" submitAction={filterAction}
                      removeAction={removeFilterAction} filterCriteria={filterCriteria}/>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel >Status</InputLabel>
                      <Select
                        label='Status'
                        value={active}
                        onChange={handleActives}
                      >
                        <MenuItem value={true}>Active only</MenuItem>
                        <MenuItem value={false}>Disabled only</MenuItem>
                        <MenuItem value={'All'}>All</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
              {
                fetching ?
                  <Stack direction="row" sx={{width:'100%', minHeight:'350px', height:'100%',  display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <CircularProgress sx={{ m: 'auto', width: '100vw' }} />
                  </Stack> :
                partners?.users?.length > 0 ?
                  <>
                    <SensieTable
                      menuItems={menuItems}
                      heads={TableHeads}
                      data={partners.users}
                      page={partners?.pagination?.currentPage-1}
                      count={partners?.pagination?.totalItems}
                      rowsPerPage={limit}
                      tableDefaultsValues={tableDefaultsValues}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      hasActiveOption
                      hasActions
                      updateStatus={updateStatus}
                      adminRole={adminRole}
                      isLoadingRow={isLoadingRow}
                      selectedIndex={selectedIndex}
                      handleSortRequest={handleSortRequest}
                      direction={direction}
                      column={column}
                      filterActions={filterActions}
                    />
                  </>
                  :
                  <>
                    {
                        'boolean' === typeof active ? 
                          <NotFoundCard text={`No ${active ? 'active': 'inactive'} admins found`}/> :
                        filterCriteria?.length > 0 ?
                          <NotFoundCard text="No admins found matching the search criteria"/> :
                          <NotFoundCard text="No admin users found, click here to add an admin" action={handleOpen}/>
                    }
                  </>
              }
            </Container >
      </Box>
    </>
  )
}

const mapStateToProps = state => ({
  // session: state.SessionPersistedReducer,
  // users: state.UserReducer.users,
  // usersByPartnerId: state.UserReducer.usersByPartnerId
})

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Users)
