import { combineReducers } from 'redux'
import SessionPersistedReducer from './common/SessionPersistedReducer'
import { PartnerReducer } from './partner/PartnerReducer'
import { AlertReducer } from './common/AlertReducer'
import { UserReducer } from './user/UserReducer'
import { HomeReducer } from './home/HomeReducer'
import { ConfirmationReducer } from './common/ConfirmationReducer'
import { EntityHelperReducer } from './common/EntityHelperReducer'
import { PartnerUserReducer } from './common/PartnerUserReducer'
import { LoginReducer } from './login/LoginReducer'
export const reducers = combineReducers({
  SessionPersistedReducer,
  PartnerReducer,
  UserReducer,
  AlertReducer,
  HomeReducer,
  ConfirmationReducer,
  EntityHelperReducer,
  PartnerUserReducer,
  LoginReducer
})
