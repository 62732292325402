import React from 'react'

import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'

const SensieTableHead = ({ ...props }) => {
  return (
    <>
      <TableHead>
        <TableRow >
          {
            props.heads.map((headCell, i) => {
              return (
                <TableCell
                  key={i}
                  sortDirection={props.direction === 'ASC' ? 'asc' : 'desc'}
                >
                  {
                    headCell.disableSorting ? headCell.label :
                      <TableSortLabel
                        active={props.column === headCell.id}
                        direction={props.direction === 'ASC' ? 'asc' : 'desc'}
                        onClick={() => props.handleSortRequest(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                  }
                </TableCell>
              )
            })
          }
          {
            props.toAssign &&
            <TableCell align="center">
              Assign
            </TableCell>
          }
          {
            props.toUnassign &&
            <TableCell align="center">
              Unassign
            </TableCell>
          }
          {
            props.hasActiveOption &&
            <TableCell align="center" sortDirection={props.direction === 'DESC' ? 'desc' : 'asc'}>
              <TableSortLabel
                active={props.column === 'isActive'}
                direction={props.direction === 'DESC' ? 'asc' : 'desc'}
                onClick={() => props.handleSortRequest('isActive')}
              >
                Status
              </TableSortLabel>
            </TableCell>
          }
          {
            props.hasActions && props.adminRole &&
            <TableCell padding='checkbox'></TableCell>
          }
        </TableRow>
      </TableHead>
    </>
  )
}

export default SensieTableHead
