import ENTITY_ACTIONS from "../../constants/entityConstants"
const initialState = {
    open: false,
    userInfo: {}
  }

export const EntityHelperReducer = (state = initialState, action) => {
      switch (action.type) {
        case ENTITY_ACTIONS.DISPLAY_ENTITY_INFO:
            return {
                ...state,
                userInfo: {...action.payload},
                open: true
            }
        case ENTITY_ACTIONS.DISMISS_INFO_DISPLAY:
            return {
                ...state,
                open:false,
                userInfo:{}
            }
        default:
            return state
      }
      
  }