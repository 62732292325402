import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Grid, Button, Typography } from '@mui/material'

import { ReactComponent as NoFound } from './../assets/image/not-found.svg'

const NotFound = ({ props }) => {
  const navigate = useNavigate()
  return (
    <>
      <Grid container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        sx={{ textAlign: 'center', p: 2, mt: '1vh' }}
      >
        <Grid item  >
          <NoFound title={''} style={{ width: '100%' }} />
        </Grid>
        <Grid item >
          <Typography variant='h1'>404</Typography>
        </Grid>
        <Grid item >
          <Typography variant='h4'>Whoooooops... this page is not available.</Typography>
        </Grid>
        <Grid item >
          <Typography variant='h6'>
            The page you're looking for isn't found :( <br />
          </Typography>
        </Grid>
        <Grid item >
          <Button size="small" variant='contained' onClick={() => navigate(`/`)} type='primary'>
            Go back
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default NotFound
