import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoColor from '../../../assets/image/sensie_color.png'
import { FormControl,  Stack, Button, Typography} from '@mui/material'

import PasswordInput from '../PasswordInput';
import LoadingButton from '@mui/lab/LoadingButton'
import PassHelper from '../PassHelper'
import { styled } from '@mui/system';

const PassForm = styled('form')({
    width:'100%'
})
const NewPasswordView = ({onSubmit, returnAction}) => {
    const [processing, setProcessing] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const [formData, setFormData] = useState({
        password:'',
        passwordConfirmation:''
    })
    const navigate = useNavigate()
    const handleAction = (e) => {
        e.preventDefault()
        setProcessing(true)
        onSubmit(formData.password, ()=>{
            setProcessing(false)
        })
    }
    return(
        <Stack alignItems="center" justifyContent="center" sx={{height:'100vh'}}>
            <Stack alignItems="center" sx={{ maxWidth: 400,width:'100%', p: 2, backgroundColor: '#151E1F', borderRadius: 2 }}>
                <img src={LogoColor} alt='Sensie logo' style={{ objectFit: 'contain', width: 150, height: 100 }} />
                <Typography sx={{opacity:.6, alignSelf:'center', width:'100%', textAlign:'center'}} variant="caption">Administrator-set temporary passwords have a seven-day expiration date.</Typography>
                <PassForm onSubmit={handleAction}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <PasswordInput label="Password"
                            identifier="password"
                            value={formData.password}
                            refreshValue={(value) => setFormData({...formData, password: value})}
                            showPass/>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <PasswordInput label="Confirm password"
                            identifier="passwordConfirmation"
                            value={formData.passwordConfirmation}
                            refreshValue={(value) => setFormData({...formData, passwordConfirmation: value})}/>
                    </FormControl>
                    <PassHelper data={formData} updateValidation={setCanSubmit}/>
                    <FormControl fullWidth >
                        <LoadingButton loading={processing} disabled={!canSubmit} sx={{ mt: 2 }} 
                            type="submit" variant='contained'>Change password</LoadingButton>
                    </FormControl>
                </PassForm>
                <Button fullWidth sx={{mt:2}} onClick={returnAction}>Back to login page</Button>
            </Stack>
        </Stack>
    
    )
}
export default NewPasswordView