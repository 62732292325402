import React, { useState } from 'react'
import { Menu, MenuItem } from '@mui/material'

import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const MenuItems = ({ ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  };
  const handleClose = () => {
    setAnchorEl(null)
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
       {props.menuItems.filter((r)=>!r.hasOwnProperty('filter') || props.filterActions(props.item)).map((option, index) => (
        // {props.menuItems.map((option, index) => (
          <MenuItem key={index}
            onClick={() => {
              if (option.action) {
                option.action(props.item, index)
              }
              handleClose()
            }}>
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default MenuItems
