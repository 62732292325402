import { createTheme } from '@mui/material/styles'

const SensieTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#11E7BC',
    },
    secondary: {
      main: '#656565',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          color: '#fff'
        }
      }
    }
  },
})

export default SensieTheme
