import api from '../../services/API'
import ACTION_TYPES from '../constants/homeConstants'

export const fetch_data_chart = (filterDate, token, partnerId, onError, onSuccess) => dispatch => {
  api.Home().fetchDataChart(filterDate, token, partnerId)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.FETCH_CHART_DATA,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const fetch_sensies_data_chart = (filterType, accessGatewayId, filterDate, token, partnerId, onError, onSuccess) => dispatch => {
  api.Home().fetchSensiesChart(filterType, accessGatewayId, filterDate, token, partnerId)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.FETCH_SENSIES_CHART,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const fetch_partners_data_chart = (filterType, filterDate, token, onError, onSuccess) => dispatch => {
  api.Home().fetchPartnerChart(filterType, filterDate, token)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.FETCH_PARTNERS_CHART,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const fetch_report_data = (filterDate, token, partnerId, onError, onSuccess) => dispatch => {
  api.Home().fetchReport(filterDate, token, partnerId)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.FETCH_REPORT,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}
