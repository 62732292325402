import ACTION_TYPES from '../../constants/sessionConstants'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import jwt_decode from 'jwt-decode'
const keyInitialState = {
  keys: [],
  loading: false,
  newSelection: false,
  unauthorizedUser: false
}
const initialState = {
  isAuthenticated: false,
  sessionData: '',
  token: '',
  userId: '',
  expToken: '',
  userData: {},
  userRoles: [],
  partnerId: '',
  partnerKeyInfo: keyInitialState
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_TOKEN:
      return {
        ...state,
        isAuthenticated: true,
        sessionData: action.payload,
        token: action.payload.idToken.jwtToken,
        userData: jwt_decode(action.payload.idToken.jwtToken),
        userRoles: state.userData['cognito:groups'],
        userId: state.userData['cognito:username'],
        expToken: state.userData.exp
        //inital state values
      }
    case ACTION_TYPES.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        sessionData: '',
        token: '',
        userId: '',
        expToken: '',
        userData: {},
        userRoles: [],
        partnerId: ''
      }
    case ACTION_TYPES.PARTNER_ID:
      return {
        ...state,
        partnerId: action.payload
      }
    case ACTION_TYPES.REMOVE_PARTNER:
      return {
        ...state,
        partnerId: '',
        partnerKeyInfo: keyInitialState
      }
    case ACTION_TYPES.START_KEY_LOADING:
      return {
        ...state,
        partnerKeyInfo: {
          ...keyInitialState,
          loading: true,
          newSelection: true
        }
      }
    case ACTION_TYPES.KEY_INFO:
      return {
        ...state,
        partnerKeyInfo: {
          keys: action.payload,
          loading: false,
          newSelection: true
        }
      }
    case ACTION_TYPES.DISMISS_NEW_SELECTION:
      return {
        ...state,
        partnerKeyInfo: {
          ...state.partnerKeyInfo,
          newSelection: false
        }
      }
    case ACTION_TYPES.DROP_KEY_INFO:
      return {
        ...state,
        partnerKeyInfo: { ...keyInitialState }
      }
    case ACTION_TYPES.DROP_KEY_INFO_WITH_UA:
      return {
        ...state,
        partnerKeyInfo: {
          ...keyInitialState,
          unauthorizedUser: true
        }
      }
    default:
      return state
  }
}

const persistConfig = {
  key: 'session',
  storage
}

const SessionPersistedReducer = persistReducer(persistConfig, reducer)
export default SessionPersistedReducer
