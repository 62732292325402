import React, { useState } from 'react'
import { TextField, FormControl, Typography, Box, InputAdornment, 
    IconButton, OutlinedInput, InputLabel, Stack } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import LoadingButton from '@mui/lab/LoadingButton'
import LogoColor from '../../assets/image/sensie_color.png'
import AppAlert from '../../components/commons/AppAlert'
import SensieLink from '../../components/commons/SensieLink';

const LoginView = ({ onSubmit }) => {
    const [login, setLogin] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false)
    const [formData, setFormData] = useState({
        email:'',
        pass:''
    })
    const handleClickShowPassword = () => {
        setPasswordShown(!passwordShown)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }
    const submitAction = (e) => {
        e.preventDefault()
        setLogin(true)
        onSubmit(formData, ()=>{
            setLogin(false)
        })
    }
    return(
        <>
            <AppAlert/>
            <Stack justifyContent="center" alignItems="center" sx={{width:'100vw', height:'100vh'}}>
                <Box sx={{ maxWidth: 400, width: '100%', textAlign: 'center', p: 2, backgroundColor: '#151E1F', borderRadius: 2 }}>
                    <Stack alignItems="center" sx={{p:2}}>
                    <img src={LogoColor} alt='Sensie' style={{ objectFit: 'contain', width: 150, height: 100 }} />
                    <Typography variant="body2">
                    Log into your account.
                    </Typography>
                    <form onSubmit={submitAction}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <TextField value={formData.email} type='email' onChange={(e) => setFormData({...formData, email: e.target.value})} label="Email" 
                        inputProps={{style:{ WebkitBoxShadow: "0 0 0 1000px #151E1F inset"}}}/>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput id="password" type={passwordShown ? 'text' : 'password'} value={formData.pass} 
                            onChange={(e) => setFormData({...formData, pass: e.target.value })} 
                        label="Password"
                        autoComplete="off"
                        inputProps={{style:{ WebkitBoxShadow: "0 0 0 1000px #151E1F inset"}}}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {passwordShown ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        }
                        />
                    </FormControl>
                    <Typography sx={{ m: 2 }} variant="caption" display="block" gutterBottom>
                        Forgot your password? <SensieLink to='../recovery' state={{ email: formData?.email }}>Reset password</SensieLink>
                    </Typography>
                    <Stack>
                        <LoadingButton loading={login} sx={{ m: 1 }} type="submit" variant='contained'>Login</LoadingButton>
                    </Stack>
                    </form>
                    </Stack>
                </Box>
            </Stack>
        </>
    )
}

export default LoginView