import { ValidationError } from 'yup'
import LoadingButton from '@mui/lab/LoadingButton'
import React, { useState, useEffect } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Typography, Modal, TextField, FormControl, Button, IconButton } from '@mui/material'

import Constants from '../commons/utils/Constants'
import { partnerScheme } from '../commons/Validations'
import ALERT_ACTIONS from '../../redux/constants/alertConstants'
import ACTION_TYPES from '../../redux/constants/partnerConstants'
import * as partnerActions from '../../redux/actions/PartnerAction'
import * as sessionActions from '../../redux/actions/SessionActions'
import SessionConstants from '../../redux/constants/sessionConstants'

import CloseIcon from '@mui/icons-material/Close'
import DomainAddIcon from '@mui/icons-material/DomainAdd'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
}

const AddPartnerModal = ({ ...props }) => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(Constants.defaultPartnerData)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const selectedPartner = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer?.partnerId?.id)

  const handleChange = (e) => {
    const { name, value } = e.target
    const field = { [name]: value }
    setData({
      ...data,
      ...field
    })
  }

  useEffect(() => {
    if (props.isEdit) {
      setData(props.item)
    } else {
      setData({ ...Constants.defaultPartnerData })
    }
  }, [props.item]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (e) => {
    e.preventDefault()
    setErrors({})
    setLoading(true)
    try {
      await partnerScheme.validate(data, { abortEarly: false })
      if (!props.isEdit)
        props.partnerActions.create_Partner(data, localStorage.getItem('idToken'), onError, onSuccessCreate)
      else
        props.partnerActions.put_partners_by_id(props.item.id, data, localStorage.getItem('idToken'), onError, onSuccessEdit)
    } catch (error) {
      setLoading(false)
      if (error instanceof ValidationError) {
        const object = {}
        error.inner.forEach(function (x) {
          if (x.path !== undefined) {
            object[x.path] = x.message
          }
        })
        setErrors(object)
      } else {
        fireAlert(error.message, "error")
      }
    }
  }
  const fireAlert = (message, severity = 'success') => {
    dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
  }
  const onSuccessEdit = (response) => {
    setLoading(false)
    if (response.status === 'success') {
      const updatedPartner = response?.data?.partner
      if (selectedPartner?.length > 0 && updatedPartner?.id === selectedPartner) {
        dispatch({ type: SessionConstants.PARTNER_ID, payload: updatedPartner })
      }
      setData({ ...Constants.defaultPartnerData })
      setErrors({})
      props.editRehidrate(response?.data?.partner)
      props.handleOpen()
      fireAlert(response.message)
    } else if (response.status === 'fail') {
      fireAlert(response.message, "error")
      setErrors(response.data)
    }
  }

  const onSuccessCreate = (response) => {
    setLoading(false)
    if (response.status === 'success') {
      setData({ ...Constants.defaultPartnerData })
      dispatch({ type: ACTION_TYPES.TRIGGER_UPDATE, payload: true })
      setErrors({})
      props.refresh()
      props.handleOpen()
      fireAlert(response.message)
    } else if (response.status === 'fail') {
      fireAlert(response.message, "error")
      setErrors(response.data)
    }
  }

  const onError = (err) => {
    fireAlert(err.message, 'error')
    setLoading(false)
  }

  const closeModal = () => {
    setData({ ...Constants.defaultPartnerData })
    setErrors({})
    props.handleOpen()
  }

  return (
    <Modal
      open={props.open}
      onClose={closeModal}
    >
      <Box sx={style} >
        <Grid container component="form" onSubmit={onSubmit} noValidate>
          <Grid item container justifyContent='space-between' alignItems='center' direction='row' mb={2}>
            <Grid item display='flex'>
              <DomainAddIcon fontSize='large' />
              <Typography ml={2} variant='h6'>Partners</Typography>
            </Grid >
            <Grid item>
              <IconButton onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <FormControl fullWidth variant="standard">
            <TextField
              label="Name" sx={{ mb: 2 }} name="name"
              value={data.name} onChange={handleChange}
              required error={((errors && errors.fields && errors.fields.name) || errors.name) && true}
              helperText={(errors && errors.fields && errors.fields.name && errors.fields.name.errors) || errors.name}
            />
            <TextField label="Description" name="description" value={data.description}
              onChange={handleChange} multiline rows={4} sx={{ mb: 2 }}
              error={((errors && errors.fields && errors.fields.description) || errors.description) && true}
              helperText={(errors && errors.fields && errors.fields.description && errors.fields.description.errors) || errors.description}
            />
          </FormControl>
          <Grid item container justifyContent='flex-end'>
            <Grid item ml={2}>
              <Button onClick={closeModal} color='secondary' variant='contained' >Cancel</Button>
            </Grid>
            <Grid item ml={2}>
              <LoadingButton loading={loading} variant='contained' type="submit">{props.isEdit ? 'Edit Partner' : 'Save Partner'}</LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

const mapStateToProps = state => ({
  session: state.SessionPersistedReducer,
  partners: state.PartnerReducer.partners
})

const mapDispatchToProps = dispatch => {
  return {
    sessionActions: bindActionCreators(sessionActions, dispatch),
    partnerActions: bindActionCreators(partnerActions, dispatch)
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AddPartnerModal)
