const ACTION_TYPES = {
  //session types
  LOGOUT: 'LOGOUT',
  GET_TOKEN: 'GET_TOKEN',
  PARTNER_ID: 'PARTNER_ID',
  REMOVE_PARTNER: 'REMOVE_PARTNER',
  KEY_INFO:'KEY_INFO',
  DROP_KEY_INFO:'DROP_KEY_INFO',
  DROP_KEY_INFO_WITH_UA:'DROP_KEY_INFO_WITH_UA',
  START_KEY_LOADING:'START_KEY_LOADING',
  DISMISS_NEW_SELECTION:'DISMISS_NEW_SELECTION'
}

export default ACTION_TYPES
