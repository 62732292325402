import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material'
const TableLoading = () => (
    <Box>
        <Typography variant='h1'>
            <Skeleton animation="wave" />
        </Typography>
        <Skeleton animation="wave" height={75} />
        <Skeleton animation="wave" height={75} />
        <Skeleton animation="wave" height={75} />
    </Box>
)
export default TableLoading 