import api from '../../services/API'
import ACTION_TYPES from '../constants/partnerConstants'

export const create_Partner = (values, token, onError, onSuccess) => dispatch => {
  api.Partner().createPartner(values, token)
    .then(response => {
      onSuccess(response.data)
    })
    .catch(err => console.log(err))
}

export const fetch_partners = (token, limit, page, active, direction, column, filter,  onError, onSuccess) => dispatch => {
  api.Partner().fetchPartners(token, limit, page, active, direction, column, filter)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.FETCH_PARTNERS,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const fetch_all_partners = (token, limit, page, active, onError, onSuccess) => dispatch => {
  api.Partner().fetchAllActivePartners(token)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.FETCH_ALL_PARTNERS,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const fetch_partners_by_id = (partnerId, token, onError, onSuccess) => dispatch => {
  api.Partner().fetchPartnerById(partnerId, token)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.FETCH_PARTNERS_BY_ID,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const put_partners_by_id = (partnerId, values, token, onError, onSuccess) => dispatch => {
  api.Partner().putPartnerById(partnerId, values, token)
    .then(response => {
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const delete_partners_by_id = (partnerId, token, onError, onSuccess) => dispatch => {
  api.Partner().deletePartnerById(partnerId, token)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.DELETE_PARTNER_BY_ID,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const fetch_partners_by_userId = (token, userId, limit, page, active, onError, onSuccess) => dispatch => {
  api.Partner().fetchPartnersByUserId(token, userId, limit, page, active)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.FETCH_PARTNERS_BY_USERID,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const set_status_partner = (partnerId, status, token, onError, onSuccess) => dispatch => {
  api.Partner().setStatusPartner(partnerId, status, token)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.UPDATE_STATUS_PARTNER,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}
