import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Divider } from '@mui/material'
import { useSelector } from 'react-redux'
import PartnerUserTable from './PartnerUserTable'
import AssignPartnerUser from './AssignPartnerUser';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const PartnerUserTabs = () => {
    const [value, setValue] = useState(0);

    const selectedPartner = useSelector(({PartnerUserReducer}) => PartnerUserReducer?.partnerInfo)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
        <Box sx={{flexGrow: 1, 
            bgColor:'background.paper',
            display:'flex',
            flexFlow:'column nowrap',
            height:'calc(100vh-64px)',
            boxSizing:"border-box"
            }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                >
                    <Tab label="Assigned administrators" {...a11yProps(0)} />
                    <Tab label="Assign admin" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    { selectedPartner &&
                        <>
                            <Divider sx={{marginTop:'1.5rem'}}/>
                            <PartnerUserTable partnerId={selectedPartner?.id}/>
                        </>
                    }
                </TabPanel>
                <TabPanel value={value} index={1}>
                    { selectedPartner &&
                        <>
                            <Divider sx={{marginTop:'1.5rem'}}/>
                            <AssignPartnerUser partnerId={selectedPartner?.id}/>
                        </>
                    }
                </TabPanel>
        </Box>
    )
}

export default PartnerUserTabs