import dayjs from 'dayjs'
import { ValidationError } from 'yup'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Box, Grid, Typography, FormControl, TextField, Button, IconButton, } from '@mui/material'

import api from '../../services/API'
import KeyIcon from '@mui/icons-material/Key'
import Constants from '../commons/utils/Constants'
import LoadingButton from '@mui/lab/LoadingButton'
import { gatewayScheme } from '../commons/Validations'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// Constants
import ALERT_ACTIONS from '../../redux/constants/alertConstants'
import SessionConstants from '../../redux/constants/sessionConstants'

import CloseIcon from '@mui/icons-material/Close'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddGatewayModal = ({ open, handleClose, partnerId }) => {
  const [data, setData] = useState(Constants.defaultGatewayData)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()

  const adminRole = useSelector(({ SessionPersistedReducer: { userData } }) =>
    userData.hasOwnProperty('cognito:groups') &&
    userData['cognito:groups'].some(x => x === "SuperAdmin"))

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({
      ...data,
      ...{ [name]: value }
    })
  }

  const handleChangeDate = (params) => {
    if (!!params && params.hasOwnProperty('$d') && dayjs(params.$d).isValid()) {
      handleChange({
        target: {
          name: 'expiration',
          value: `${dayjs(params.$d).format("MM-DD-YYYY")}`
        }
      })
    }
  }

  const closeModal = () => {
    setData({ ...Constants.defaultGatewayData })
    setErrors({})
    handleClose()
    setLoading(false)
  }

  const onSubmit = async () => {
    try {
      setErrors({})
      setLoading(true)
      let dataToValidate = data
      dataToValidate = dataToValidate.limitUsers === '' ? { ...dataToValidate, limitUsers: null } : dataToValidate
      dataToValidate = dataToValidate.limitRecords === '' ? { ...dataToValidate, limitRecords: null } : dataToValidate
      let validation = await gatewayScheme.validate(dataToValidate, { abortEarly: false })
      if (partnerId && partnerId.length > 0) {
        validation = {
          ...validation,
          ...{ limitUsers: !validation.limitUsers ? -1 : parseInt(validation.limitUsers) },
          ...{ limitRecords: !validation.limitRecords ? -1 : parseInt(validation.limitRecords) }
        }
        const { data, status } = await api.Gateway().createGateway(partnerId, validation)
        if (status !== 200) throw new Error("An error has occurred")
        if (data.status !== 'success') throw new Error(data.message)
        try {
          dispatch({ type: SessionConstants.START_KEY_LOADING })
          dispatch({ type: SessionConstants.KEY_INFO, payload: data.data })
        } catch (error) {
          dispatch({ type: SessionConstants.DROP_KEY_INFO })
        }
        dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: data.message } })
        closeModal()
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        let errors = {}
        error.inner.forEach((e) => {
          errors[e.path] = e.message
        })
        setErrors(errors)
      } else {
        dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: error.message ?? 'An error has occurred', severity: 'error' } })
        closeModal()
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={style}>
        <Grid container>
          <Grid item container justifyContent='space-between' alignItems='center' direction='row' mb={2}>
            <Grid item display='flex' >
              <KeyIcon fontSize='large' />
              <Typography ml={2} variant='h6'>Key</Typography>
            </Grid >
            <Grid item>
              <IconButton onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <FormControl fullWidth variant="standard">
            <TextField label="Name" name="name"
              required
              inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px #151E1F inset" }, maxLength: 255 }}
              error={!!errors?.fields?.name || !!errors?.name}
              helperText={errors?.fields?.name?.errors[0] || errors?.name}
              value={data.name} onChange={handleChange} sx={{ mb: 2 }}
              disabled={false} autoComplete="off"
            />
          </FormControl>
          {!!adminRole &&
            <>
              <FormControl fullWidth variant="standard">
                <TextField label="User limit" name="limitUsers"
                  inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px #151E1F inset" } }}
                  value={data.limitUsers} onChange={handleChange} sx={{ mb: 2 }}
                  error={!!errors?.fields?.limitUsers || !!errors?.limitUsers}
                  helperText={errors?.fields?.limitUsers?.errors[0] || errors?.limitUsers}
                  disabled={false} autoComplete="off"
                />
              </FormControl>
              <FormControl fullWidth variant="standard">
                <TextField label="Sensies limit" name="limitRecords"
                  inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px #151E1F inset" } }}
                  value={data.limitRecords} onChange={handleChange} sx={{ mb: 2 }}
                  disabled={false} autoComplete="off"
                  error={!!errors?.fields?.limitRecords || !!errors?.limitRecords}
                  helperText={errors?.fields?.limitRecords?.errors[0] || errors?.limitRecords}
                />
              </FormControl>
              <FormControl fullWidth variant="standard">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Expiration date"
                    value={data.expiration}
                    onChange={handleChangeDate}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={dayjs()}
                  />
                </LocalizationProvider>
              </FormControl>
            </>
          }


          <Grid item container justifyContent='flex-end' sx={{ marginTop: '1rem' }}>
            <Grid item ml={2}>
              <Button onClick={closeModal} color='secondary' variant='contained' >Cancel</Button>
            </Grid>
            <Grid item ml={2}>
              <LoadingButton loading={loading} variant='contained' onClick={onSubmit}>Add key</LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default AddGatewayModal;
