const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const camelToTitleCase = (str) => {
  const result = str.replace(/([A-Z])/g, " $1")
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
}

const differenceOfSet = (listA, listB) => {
  let difference = listA.filter(x => {
    let exists = listB.find(y => y.id == x.id)
    if (exists === undefined || exists === null)
      return x
  })
  return difference;
}

const utils = {
  differenceOfSet,
  capitalizeFirstLetter,
  camelToTitleCase
}

export default utils
