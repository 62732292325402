import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
const ConfirmRegenerateDialog = ({open, handleClose, handleConfirm}) => {
    return(
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Regenerate Key
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The existing API Key will be deleted as a result of this action. All applications that use the existing API Key will cease to function properly.
            Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="primary">Keep key</Button>
          <Button onClick={handleConfirm} variant="contained" color="error">
            Yes, regenerate key
          </Button>
        </DialogActions>
      </Dialog>
    )
}

export default ConfirmRegenerateDialog