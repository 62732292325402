
import ALERT_ACTIONS from "../../constants/alertConstants";
const initialState = {
    open: false,
    message: '',
    severity: 'success'
  }

export const AlertReducer = (state = initialState, action) => {
      switch (action.type) {
        case ALERT_ACTIONS.FIRE_ALERT:
            return {
                open: true,
                message: action.payload.message,
                severity: action.payload.severity
            }
        case ALERT_ACTIONS.DISMISS_ALERT:
            return initialState
        default:
            return state
      }
      
  }