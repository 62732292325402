import { CognitoUserPool } from "amazon-cognito-identity-js"

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
  //FOR TEST TOKENS REFRESH AND LOGOUT:
  //ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID_TEST_REFRESH_TOKEN
}

export default new CognitoUserPool(poolData)
