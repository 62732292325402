import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Menu, Grid, Divider, Typography, Button, Avatar, Container } from '@mui/material'

import SessionConstants from '../../redux/constants/sessionConstants'
import { AccountContext } from '../../components/logSession/Account'

import IconButton from '@mui/material/IconButton'
import AccountCircle from '@mui/icons-material/AccountCircle'

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl);
  const { logout } = useContext(AccountContext)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const session = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer)
  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' })
    dispatch({ type: SessionConstants.REMOVE_PARTNER })
    dispatch({ type: 'CLEAN_ALL_PARTNERS' })
    logout()
    localStorage.clear()
    navigate('/login')
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <IconButton
        size="large"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
        open={open}
        onClose={handleClose}>
        <Container
        >
          <Grid container direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Avatar
                sx={{ bgcolor: '#11E7BC', width: 56, height: 56, m: 1 }}
              >
                {session?.userData?.name.charAt(0).toUpperCase()}
              </Avatar>
            </Grid>
            <Grid item>
              <Typography >
                {session?.userData?.name + ' ' + session?.userData?.family_name}
              </Typography>
            </Grid>
            <Grid item >
              <Typography variant='subtitle2'>
                {session?.userData?.email}
              </Typography>
            </Grid>
            <Grid item sx={{ mb: 1 }}>
              {
                session?.userData?.['cognito:groups'].map((role, i) => {
                  return (
                    <Typography variant='caption' sx={{ opacity: '.5' }} key={i}>
                      {role}
                    </Typography>
                  )
                })
              }
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Grid container pt={1} pr={1} justifyContent="flex-end">
          <Grid item>
            <Button onClick={handleLogout}>Log out</Button>
          </Grid>
        </Grid>
      </Menu>
    </>
  )
}

export default UserMenu
