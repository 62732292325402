import React from 'react';
import { useSelector } from 'react-redux'
import { IconButton, Divider, Stack, List, ListItemButton, ListItemIcon, ListItemText, Button, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { DrawerHeader, Drawer } from '../../styles/Components/navigation/LayoutStyle'
import { styled } from '@mui/system'

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import HomeIcon from '@mui/icons-material/Home'
import KeyIcon from '@mui/icons-material/Key';
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import StoreIcon from '@mui/icons-material/Store';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const PartnerButton = styled(StoreIcon)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all 1s easi-in-out',
  ':hover': {
    fill: theme.palette.primary.main
  }
}))

const LayoutDrawer = ({ open, handleDrawerOpen, openPartnerSelectFn }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const adminRole = useSelector(({ SessionPersistedReducer }) =>
    SessionPersistedReducer?.userData?.['cognito:groups']?.some(x => x === "SuperAdmin"))
  const selectedPartner = useSelector(({ SessionPersistedReducer }) => SessionPersistedReducer.partnerId)
  const menuItems = [
    { name: 'Dashboard', icon: <HomeIcon />, link: '/', show: true },
    { name: 'Keys', icon: <KeyIcon />, link: '/keys', show: true },
    { name: 'Admins', icon: <GroupAddIcon />, link: '/admins', show: adminRole },
    { name: 'Partners', icon: <DomainAddIcon />, link: '/partner', show: adminRole }
  ]

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerOpen}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Stack justifyContent="space-between" sx={{ height: '100%' }}>
        <Stack alignItems="stretch">
          {adminRole &&
            <Box sx={{ py: '1rem', px: '0.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {open ?
                <Button onClick={openPartnerSelectFn}>{!selectedPartner || selectedPartner?.id.length === 0 ? 'Select partner' : 'Change partner'}</Button> :
                <PartnerButton onClick={openPartnerSelectFn} />
              }
            </Box>
          }
          <List>
            {
              menuItems.map((item, index) => (
                <React.Fragment key={index}>
                  {
                    item.show &&
                    <ListItemButton
                      onClick={() => navigate(item.link)}
                      sx={{ minHeight: 48, px: 2.5, }}
                    >
                      <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  }
                </React.Fragment>
              ))
            }
          </List>
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default LayoutDrawer
