import * as yup from "yup"

export const userScheme = yup.object().shape({
  email: yup.string().email('Email must be a valid email').required('This is a required field'),
  lastname: yup.string().required('This is a required field'),
  name: yup.string().required('This is a required field'),
})

export const partnerScheme = yup.object().shape({
  name: yup.string().required('This is a required field'),
  description: yup.string().test('len', 'This field must contain at least 10 characters.', val => `${val}`.length >= 10 || `${val}`.length === 0)
})

export const gatewayScheme = yup.object().shape({
  name: yup.string().required('This is a required field'),
  limitUsers: yup.number().nullable(true).typeError('You must specify a valid  number').positive(),
  limitRecords: yup.number().nullable(true).typeError('You must specify a valid  number').positive()
})
