import React, { useState, useEffect, useCallback } from 'react';
import AppCard from './AppCard'
import { Grid, CircularProgress, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

const ListGateways = ({handleOpenModal}) => {
    const [keyList, setKeyList] = useState([])
    const partnerId = useSelector(({SessionPersistedReducer}) => SessionPersistedReducer?.partnerId?.id)
    const keys = useSelector(({SessionPersistedReducer}) => SessionPersistedReducer?.partnerKeyInfo?.keys)
    const loadingKeys = useSelector(({SessionPersistedReducer}) => SessionPersistedReducer?.partnerKeyInfo?.loading)
    const unauthorizedUser = useSelector(({SessionPersistedReducer}) => SessionPersistedReducer?.partnerKeyInfo?.unauthorizedUser)
    
    useEffect(()=>{
        setKeyList(keys)
    },[keys])
    
    const refreshStatusFn = useCallback((updatedKey) => {
        let upKeys = keyList
        const foundIndex = upKeys.findIndex(p=>p.id === updatedKey?.id)
        upKeys[foundIndex].isActive = updatedKey.isActive
        setKeyList([...upKeys])
    },[keyList])

    return(
        <Grid container spacing={2}>
            {loadingKeys ? 
                <Grid container direction='row' sx={{ minHeight: 350 }}>
                    <CircularProgress sx={{ m: 'auto', width: '100vw' }} />
                </Grid> : keyList?.length <= 0 ?
                <Grid container sx={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center',
                    padding:'4rem 0'}}>
                    <Grid item sx={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', 
                        border: '1px solid #B0B3B9', borderRadius: 4, maxWidth: 400, padding:'4rem 6rem',
                        gap: '1rem', cursor:`${!!partnerId && !unauthorizedUser?'pointer':'default'}`
                    }} onClick={() => {if(!!partnerId && !unauthorizedUser) handleOpenModal()}}>
                        { !!partnerId && !unauthorizedUser ? <AddCircleOutlineRoundedIcon sx={{ fontSize: 60, fill: '#B0B3B9' }} /> :
                            <WarningAmberRoundedIcon sx={{ fontSize: 60, fill: '#B0B3B9' }} />}
                        <Typography variant='body1' sx={{ textAlign: 'center', color: '#B0B3B9' }}>
                            {!partnerId ? 'Select a partner first' : !unauthorizedUser ? 'Click here to generate your set of keys' : 'It is not possible to display the keys associated with this partner.'}
                        </Typography>
                        
                    </Grid>
                </Grid>
                :
                keyList.map(key=>(
                    <Grid item key={key.id} xs={6}>
                        <AppCard appInfo={key} refreshStatusFn={refreshStatusFn}/>
                    </Grid>
                ))
            }
                
        </Grid>
    )
}
export default ListGateways