import React, { useCallback, useEffect, useState } from 'react';
import { Skeleton, Typography, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import SensieTable from '../commons/SensieTable';
import api from '../../services/API'
import ALERT_ACTIONS from '../../redux/constants/alertConstants';
import PARTNERUSER_ACTIONS from '../../redux/constants/partnerUserConstants';
import TableLoading from '../commons/TableLoading';

const TableHeads = [
    { id: 'name', label: 'Name', disableSorting: false },
    { id: 'lastname', label: 'Last name', disableSorting: false },
    { id: 'email', label: 'Email', disableSorting: false }
]
const TableDefaultsValues = {
    page: 0,
    rowsPerPage: 15,
    pages: [15, 25, 50],
  }
const PartnerUserTable = ({partnerId}) => {
    const [quickElementsDelete] = useState([])
    const [partnerData, setPartnerData] = useState(null)
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(15)
    const [active, setActive] = useState('All')
    const [direction, setDirection] = useState('ASC')
    const [column, setColumn] = useState('name')

    const quickChange = useSelector(({PartnerUserReducer}) => PartnerUserReducer?.unassignedUserId)

    useEffect(()=>{
        if(quickChange){
            if(!quickElementsDelete.includes(quickChange)) quickElementsDelete.push(quickChange);
            dispatch({type:PARTNERUSER_ACTIONS.CLEAR_QUICK_DELETE})
            if(partnerData?.users?.length > 0 && quickElementsDelete.length > 0)
            {
                const newData = partnerData.users.filter((e)=>(
                    !quickElementsDelete.includes(e.id) 
                ))
                setPartnerData({...partnerData, users:[...newData]})
            }
        }
    },[quickChange])
    
    const dispatch = useDispatch()
    
    const fireAlert = useCallback((message, severity = 'success') => {
        dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
    },[dispatch])

    const fetchUsersByPartnerId = useCallback(async () => {
        try {
            setLoadingUsers(true)
            const { data } = await api.User().fetchUsersByPartnerId(partnerId, limit,page + 1, active, null, direction, column)
            if(data.status === 'fail') throw new Error(data.message)
            setPartnerData({ users: data?.data?.users, pagination: data?.data?.pagination})
        } catch (error) {
            fireAlert(error.message)
        } finally {
            setLoadingUsers(false)
        }

    },[fireAlert, partnerId, active, column, direction, limit, page])

    const unassignUser = useCallback((item) => (
        api.User().unassingUserPartner(item.id, partnerId, localStorage.getItem('idToken'))
    ),[partnerId])

    useEffect(()=>{
        (fetchUsersByPartnerId)()
    },[limit, page, active, direction])

    const handlePageChange = useCallback((event, newPage) => {
        setPage(newPage)
    },[])

    const handleChangeRowsPerPage = useCallback((event) => {
        setLimit(+event.target.value);
        setPage(0)
    },[])

    const handleSortRequest = useCallback((cellId) => {
            if (cellId !== column){
                setColumn(cellId)
                setDirection('ASC')
            } else {
                setDirection(direction==='ASC'?'DESC':'ASC')
            }
       
      },[column, direction])
    return(
        <>
            { loadingUsers ?
                <TableLoading/> :
                partnerData &&
                <>
                    <SensieTable
                        heads={TableHeads}
                        data={partnerData.users}
                        page={partnerData.pagination.currentPage-1}
                        count={partnerData.pagination?.totalItems}
                        rowsPerPage={limit}
                        tableDefaultsValues={TableDefaultsValues}
                        handleChangePage={handlePageChange}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleSortRequest={handleSortRequest}
                        toUnassign
                        toUnassignAction={unassignUser}
                        direction={direction}
                        column={column}
                        />
                </>
            }
        </>
    )
}

export default PartnerUserTable