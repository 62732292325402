import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Paper, IconButton, InputBase, Divider, Stack, Typography } from '@mui/material'
import ALERT_ACTIONS from '../../redux/constants/alertConstants';
import SearchIcon from '@mui/icons-material/Search';

class InfoHandler extends Error {
  constructor(...params) {
    super(...params)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InfoHandler)
    }
    this.name = 'InfoHandler'
    this.severity = 'info'
  }

}

const TableSearchBar = ({ label, submitAction, removeAction, filterCriteria }) => {
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()
  const search = (e) => {
    e.preventDefault()
    try {
      if (searchText?.length < 3) throw new InfoHandler('The search text must be at least 3 characters long')
      if (searchText.split(' ').length > 10) throw new InfoHandler('The search field is limited to a maximum of ten words.')
      submitAction(encodeURIComponent(searchText))
    } catch (error) {
      dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: error.message, severity: error.severity } })
    }
  }

  const clearSearch = () => {
    setSearchText('')
    removeAction()
  }
  return (
    <Stack spacing={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <Paper
        component="form"
        onSubmit={search}
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: 400, minWidth: 300 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={`Search ${label || ''}`}
          value={searchText}
          onChange={({ target: { value } }) => setSearchText(value)}
          inputProps={{ 'aria-label': `search ${label}` }}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton type="submit" size='5' sx={{ p: '5px' }} aria-label="search" >
          <SearchIcon />
        </IconButton>

      </Paper>
      {
        filterCriteria?.length > 0 &&
        <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Typography sx={{ opacity: 0.6 }}>Filtering by</Typography>
          <Typography><b>{filterCriteria.join(' ')}</b></Typography>
          <Divider sx={{ height: 14, m: 0.5 }} orientation="vertical" />
          <Typography sx={{ cursor: 'pointer', fontSize: '.85rem' }}
            onClick={clearSearch}>Remove filter</Typography>
        </Stack>
      }
    </Stack>
  )
}
export default React.memo(TableSearchBar)
