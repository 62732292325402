import React from 'react'
import { ResponsiveLine } from '@nivo/line'
// make sure parent container have a defined height when using

const Chart = (props) => {
  const getMaxValue = chartData => {

    const maxArray = chartData.map(data =>
      data.data.reduce((max, p) => (p.y > max ? p.y : max), data.data[0].y)
    )

    const minChartY = (value) => value <= 10
    let result = maxArray.every(minChartY)
    let padding
    if (result) {
      padding = 10
    } else {
      padding = 'auto'
    }
    return padding
  }

  return (
    <div style={{ height: 600, padding: 10, overflow: 'hidden' }}>
      <ResponsiveLine
        data={props.data}
        margin={{ top: 20, right: 10, bottom: 140, left: 50 }}
        xScale={{
          type: 'point'
        }}
        yScale={{
          type: 'linear',
          max: getMaxValue(props.data)
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          color: 'white',
          orient: 'bottom',
          tickSize: 15,
          tickPadding: 5,
          tickRotation: -60,
          legend: '',
          legendOffset: 50,
          legendPosition: 'middle'
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -40,
          legendPosition: 'middle'
        }}
        colors={{ scheme: props.color || 'nivo' }}
        enableSlices="x"
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        curve="monotoneX"
        useMesh={true}
        theme={{
          "textColor": "#ffffff",
          "tooltip": {
            "container": {
              "background": "#ffffff",
              "color": "#333333",
              "fontSize": 12
            },
          },
          "grid": {
            "line": {
              "stroke": "#454545",
              "strokeWidth": 2
            }
          },
        }}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 120,
            itemsSpacing: 50,
            itemDirection: 'left-to-right',
            itemWidth: 70,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </div>
  )
}


export default Chart
