import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { bindActionCreators, compose } from 'redux'

import BarChart from '../Home/BarChart'
import * as homeActions from '../../redux/actions/HomeActions'

import { Button, Grid, Typography, FormControl, MenuItem, Select, Dialog, IconButton, DialogActions, DialogContent, Skeleton, DialogTitle, CircularProgress } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

const PartnersChartModal = ({ open, ...props }) => {

  const [filterType, setFilterType] = useState('all')
  const [filterDate, setFilterDate] = useState('1w')
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [keys, setKeys] = useState([])
  const [fetching, setFetching] = useState()

  useEffect(() => {
    if (open === 'Partners') {
      setFetching(true)
      props.homeActions.fetch_partners_data_chart(filterType, filterDate, localStorage.getItem('idToken'), onError, onSuccess)
    } else {
      setLoading(true)
    }
  }, [open, filterType, filterDate])// eslint-disable-line react-hooks/exhaustive-deps

  const onSuccess = (res) => {
    if (res.status === 'success') {
      setLoading(false)
      const object = []
      const keys = []
      res?.data?.response?.map((x) => {
        object.push({ "name": Object.keys(x)[0], "value": Object.values(x)[0] })
        return setData(object[0])
      })
      object[0].value.map((x) => {
        keys.push({ "value": Object.keys(x) })
        return setKeys(keys[0].value.filter(w => w !== 'id' && w !== 'partners'))
      })
    } else {
      setData([])
    }
    setFetching(false)
  }

  const onError = (err) => {
    console.error(err)
  }

  const handleDayChange = (e) => {
    setFilterDate(e.target.value)
  }
  const handleFilterChange = (e) => {
    setFilterType(e.target.value)
  }

  return (
    <>
      <Dialog fullWidth maxWidth={'xl'} open={open === 'Partners'} onClose={props.handleClose}>
        <DialogTitle>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Typography variant='h4'>
                Partners
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction='row' alignItems='center'>
                {
                  fetching &&
                  <Grid item >
                    <CircularProgress color="primary" size={25} />
                  </Grid>
                }
                <FormControl sx={{ ml: 2, minWidth: 120 }}>
                  <Select
                    size='small'
                    value={filterDate}
                    defaultValue={'1d'}
                    onChange={handleDayChange}
                  >
                    <MenuItem value={'1w'}>Last week</MenuItem>
                    <MenuItem value={'1M'}>Last month</MenuItem>
                    <MenuItem value={'1y'}>Last year</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ ml: 2, minWidth: 120 }}>
                  <Select
                    size='small'
                    value={filterType}
                    defaultValue={'1d'}
                    onChange={handleFilterChange}
                  >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'users'}>Users</MenuItem>
                    <MenuItem value={'sensies'}>Sensies</MenuItem>
                  </Select>
                </FormControl>
                <IconButton onClick={props.handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ mb: 5 }}>
          {
            loading ? <Skeleton animation="wave" variant="rectangular" width={'100%'} height={400} />
              :
              <>
                {
                  data?.value?.length > 0 && data.length !== 0 ?
                    <BarChart data={data} keys={keys} />
                    :
                    <Grid container justifyContent="center" alignItems='center' sx={{ height: 400, border: '1px solid #B0B3B9', borderRadius: 4, }}>
                      <Typography variant='h6'>No data to show</Typography>
                    </Grid>
                }
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}


const mapStateToProps = state => ({
  partnersData: state.HomeReducer.partners
})

const mapDispatchToProps = dispatch => {
  return {
    homeActions: bindActionCreators(homeActions, dispatch),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PartnersChartModal)
