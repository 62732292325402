import { styled } from '@mui/system';
import { Link } from 'react-router-dom'
const SensieLink = styled(Link)(({theme})=>({
    color:theme.palette.primary.main,
    opacity:.7,
    textDecoration:'none',
    lineHeight:1,
    transition:'all .3s ease-in',
    '&:hover':{
        opacity:1
    }
}))
export default SensieLink