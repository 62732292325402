import jwt_decode from 'jwt-decode'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import React, { useCallback, useMemo, useState } from 'react'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'

import UserPool from '../../UserPool'
import NewPassword from './NewPassword'
import LoginView from '../../components/Login/LoginView'
import api from '../../services/API'
import ALERT_ACTIONS from '../../redux/constants/alertConstants'
import ACTION_TYPES from '../../redux/constants/sessionConstants'

const Login = () => {
  const [changeUser, setChangeUser] = useState(null)
  let navigate = useNavigate()

  const dispatch = useDispatch()
  const emailPattern = useMemo(() => /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i, [])

  const fireAlert = useCallback((message, severity = 'success') => {
    dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
  }, [dispatch])

  const onSubmit = useCallback((formData, cb) => {
    try {
      const throwError = message => { throw new Error(message) }
      if (Object.values(formData).some(f => f.length === 0)) throwError("All fields must be filled out.")
      if (!emailPattern.test(formData.email)) throwError("Incorrect username or password.")
      const cognitoUser = new CognitoUser({
        Username: formData.email,
        Pool: UserPool
      })
      const authDetails = new AuthenticationDetails({
        Username: formData.email,
        Password: formData.pass
      })
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (data) => {
          dispatch({
            type: ACTION_TYPES.GET_TOKEN,
            payload: data
          })
          localStorage.setItem('expToken', JSON.stringify(jwt_decode(data.idToken.jwtToken).exp))
          localStorage.setItem('idToken', data.idToken.jwtToken)
          navigate("../")
        },
        onFailure: (err) => {
          fireAlert(err.message, 'error')
          cb()
        },
        newPasswordRequired: (userAttributes) => {
          setChangeUser(cognitoUser)
          fireAlert('Please create a new password', 'info')
        }
      })
    } catch (error) {
      fireAlert(error.message, 'error')
      cb()
    }
  }, [dispatch, emailPattern, fireAlert, navigate])
  return (
    <>
      {
        !changeUser ? <LoginView onSubmit={onSubmit} /> : <NewPassword cognitoUser={changeUser} returnAction={() => setChangeUser(null)} />
      }

    </>
  )
}
export default Login
