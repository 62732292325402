import CONFIRMATION_ACTIONS from "../../constants/confirmationConstants"
const initialState = {
    open: false,
    message: 'Are you sure you want to delete this element?',
    cancelText: 'Cancel',
    submitText: 'Delete',
    record:{}
  }

export const ConfirmationReducer = (state = initialState, action) => {
      switch (action.type) {
        case CONFIRMATION_ACTIONS.FIRE_CONFIRMATION:
            return {
                open: true,
                record: action?.payload?.record,
                message: action?.payload?.message ?? initialState.message,
                cancelText: action?.payload?.cancelText ?? initialState.cancelText,
                submitText: action?.payload?.submitText ?? initialState.submitText  
            }
        case CONFIRMATION_ACTIONS.DISMISS_CONFIRMATION:
            return initialState
        default:
            return state
      }
      
  }