const ACTION_TYPES = {
  CREATE_USER: 'CREATE_USER',
  FETCH_USERS: 'FETCH_USERS',
  FETCH_USERS_ACTIVE: 'FETCH_USERS_ACTIVE',
  FETCH_USERS_BY_ID: 'FETCH_USERS_BY_ID',
  PUT_USER_DATA: 'PUT_USER_DATA',
  DELETE_USER_BY_ID: 'DELETE_USER_BY_ID',
  FETCH_USERS_BY_PARTNERID: 'FETCH_USERS_BY_PARTNERID',
  REMOVE_USER_FILTER: 'REMOVE_USER_FILTER',
  SET_USER_FILTER_CRITERIA:'SET_USER_FILTER_CRITERIA'
}

export default ACTION_TYPES
