import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { LinearProgress }  from '@mui/material';
import api from '../../services/API'
import { useSelector, useDispatch } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton';
import ALERT_ACTIONS from '../../redux/constants/alertConstants';

const TokenVisualizer = ({gatewayId, notificate}) => {
    const [ token, setToken ] = useState(null)
    const [ remainingTime, setRemainingTime ] = useState(100);
    const [ isActive, setIsActive ] = useState(false);
    const [ fetching, setFetching ] = useState(false);
  
    const handleTooltipOpen = ({target}) => {
      navigator.clipboard.writeText(target.innerHTML)
      notificate('Api key copied to clipboard.')
    };
  
    useEffect(()=>{
        let interval;
        if(isActive && remainingTime > 0) {
            interval = setInterval(() => {
               setRemainingTime(remainingTime => remainingTime - 1)
           }, 80)
        } else {
            setIsActive(false)
            setRemainingTime(100)
            setToken(null)
        }
        return () => clearInterval(interval)
    },[isActive,remainingTime])
    const dispatch = useDispatch()
    const partnerId = useSelector(({SessionPersistedReducer}) => SessionPersistedReducer.partnerId, ()=>true);
    const fetchToken = async () => {
        if(partnerId && partnerId.hasOwnProperty('id')){
            try {
                setFetching(true)
                const {data} = await api.Gateway().fetchToken(partnerId.id, gatewayId)                
                if(data.status === 'fail') throw new Error(data.message)
                setToken(data.data.value)
                setIsActive(true)
            } catch (error) {
                dispatch({type: ALERT_ACTIONS.FIRE_ALERT, payload: { message:error.message, severity:'error'}})
            } finally { 
                setFetching(false)
            }
            
        }
    }

    return(
        <Box sx={{display:'flex', flexDirection:'column', width:'100%'}}>
            <Box sx={{display:'flex', flexDirection:'row', flexWrap: 'wrap', gap:'.6rem', 
                alignItems:'center',marginBottom:`${isActive ? "10px":"12px"}`}}>
                <p>API Key:</p>{!!token && 
                    <div>
                        <p onClick={handleTooltipOpen} style={{ cursor:'pointer' }}>{token}</p>
                    </div>
                }
                {!isActive &&
                <>
                    <LoadingButton
                        loading={fetching}
                        size="small"
                        onClick={fetchToken}
                        variant="outlined"
                    >
                        Show
                    </LoadingButton>
                </>
                    
                }
            </Box>
            { !!isActive && 
                <LinearProgress variant="determinate" value={remainingTime} />
            }
        </Box>
    )

}

export default TokenVisualizer