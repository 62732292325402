import React from 'react'
import { Dialog, Typography,IconButton, Slide, AppBar, Toolbar } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';


import PARTNERUSER_ACTIONS from '../../redux/constants/partnerUserConstants'
import PartnerUserTabs from './PartnerUserTabs';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PartnerUserModal = () => {
    const dispatch = useDispatch()
    
    const selectedPartner = useSelector(({PartnerUserReducer}) => PartnerUserReducer?.partnerInfo)
    const open = useSelector(({PartnerUserReducer}) => PartnerUserReducer?.open)
    const handleClose = () => {
        dispatch({type:PARTNERUSER_ACTIONS.DISMISS_INFO_DISPLAY_PARTNERUSER})
    }
    return(
        <Dialog onClose={handleClose} open={open} TransitionComponent={Transition}
            maxWidth='xl'
            fullWidth>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar sx={{justifyContent:"space-between"}}>
                    <Typography variant="h6">{selectedPartner.name}</Typography>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <PartnerUserTabs/>
        </Dialog>
    )
}

export default PartnerUserModal