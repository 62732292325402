import ACTION_TYPES from "../../constants/partnerConstants";
const partnerEditedSelectorDefaults = {
  change: false,
  data: [],
  actionType: ''
}
const initialState = {
  allPartners: {
    data: {
      partner: []
    }
  },
  partners: {},
  partnersTotal: 0,
  triggerUpdateSelect: false,
  partnerData: {},
  newPartnerData: {},
  partnersByUserId: {},
  partnerStatusData: {},
  outsideSelectorTrigger: false,
  partnerEditedSelector: partnerEditedSelectorDefaults,
  filterCriteria: null
}
export const PartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CREATE_PARTNER:
      return {
        ...state
      }
    case ACTION_TYPES.FETCH_PARTNERS:
      return {
        ...state,
        partners: { ...action.payload },
        partnersTotal: action.payload.data.pagination.totalItems
      }
    case ACTION_TYPES.FETCH_ALL_PARTNERS:
      return {
        ...state,
        allPartners: { ...action.payload }
      }
    case ACTION_TYPES.CLEAN_ALL_PARTNERS:
      console.log('hola2')
      return {
        ...state,
        allPartners: {
          data: {
            partner: []
          }
        },
      }
    case ACTION_TYPES.FETCH_PARTNERS_BY_ID:
      return {
        ...state,
        partnerData: { ...action.payload }
      }
    case ACTION_TYPES.PUT_PARTNER_DATA:
      return {
        ...state,
        newPartnerData: { ...action.payload }
      }
    case ACTION_TYPES.DELETE_PARTNER_BY_ID:
      return {
        ...state
      }
    case ACTION_TYPES.FETCH_PARTNERS_BY_USERID:
      return {
        ...state,
        partnersByUserId: { ...action.payload }
      }
    case ACTION_TYPES.UPDATE_STATUS_PARTNER:
      return {
        ...state,
        partnerStatusData: { ...action.payload }
      }
    case ACTION_TYPES.TRIGGER_UPDATE:
      return {
        ...state,
        triggerUpdateSelect: action.payload
      }
    case ACTION_TYPES.REMOVE_FILTER:
      return {
        ...state,
        filterCriteria: []
      }
    case ACTION_TYPES.SET_FILTER_CRITERIA:
      return {
        ...state,
        filterCriteria: action.payload
      }
    case ACTION_TYPES.TRIGGER_SELECTOR_UPDATE:
      return {
        ...state,
        partnerEditedSelector: {
          change: true,
          data: action.payload.partners,
          actionType: action.payload?.actionType || 'edit'
        }
      }
    case ACTION_TYPES.DROP_SELECTOR_UPDATE:
      return {
        ...state,
        partnerEditedSelector: partnerEditedSelectorDefaults
      }
    case ACTION_TYPES.CHANGE_SELECTOR_STATE:
      return {
        ...state,
        outsideSelectorTrigger: action.payload
      }
    default:
      return state
  }
}
