import React, { useState, useCallback, useEffect} from 'react'

import { Card, Box , CardContent, CardActions,  Typography, Stack } from '@mui/material'
import api from '../../services/API'
import { useSelector } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import dayjs from 'dayjs';

import LoadingButton from '@mui/lab/LoadingButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import TokenVisualizer from '../Gateway/TokenVisualizer'
import StatusIcon from '../commons/StatusIcon'
import { useDispatch } from 'react-redux'
import ALERT_ACTIONS from '../../redux/constants/alertConstants'
import EditGatewayModal from './EditGatewayModal'
import ConfirmRegenerateDialog from "./ConfirmRegenerateDialog";
const AppCard = ({appInfo, refreshStatusFn}) => {
    // const [appInfo, setAppData] = useState({})
    const [updatingStatus, setUpdatingStatus] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

    const partnerId = useSelector(({SessionPersistedReducer}) => SessionPersistedReducer.partnerId, ()=>true);
    const dispatch = useDispatch()

    const regenerateToken = useCallback(async () => {
        if(partnerId && partnerId.hasOwnProperty('id')){
            try {
                setConfirmDialogOpen(false)
                setRefreshing(true)
                const { data } = await api.Gateway().regenerateToken(partnerId.id, appInfo.id)

                if(data.status!=='success') throw new Error(data.message)
                dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: `${data.message}` } })
                setRefreshing(false)
                
            } catch (error) {
                dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: `${error.message}`, severity:'error' } })
                setRefreshing(false)
            }
        }
    },[dispatch, partnerId, appInfo])
    
    const notificationHandler = useCallback((message) => {
        dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message } })
    },[dispatch])

    const updateStatus = useCallback(async () => {

        if(partnerId && partnerId.hasOwnProperty('id')){
            try {
                setUpdatingStatus(true)
                const { data } = await api.Gateway().updateStatus(partnerId.id, appInfo.id, appInfo.isActive ? 0 : 1)

                if(data.status!=='success') throw new Error(data.message)
                refreshStatusFn(data.data.accessGateway)
                // setAppData({...appInfo, isActive:data.data.accessGateway.isActive})
                dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: `${data.message}` } })
                setUpdatingStatus(false)
                
            } catch (error) {
                dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: `${error.message}`, severity:'error' } })
                setUpdatingStatus(false)
            }
        }
    },[dispatch, partnerId, appInfo, refreshStatusFn])

    const handleClose = () => {
        setEditOpen(false)
    }

    return(
        <>
            { partnerId && 
                <EditGatewayModal open={editOpen} partnerId={partnerId.id} handleClose={handleClose} 
                    itemData={{id:appInfo.id, name:appInfo.name, metadata:appInfo.accessGatewayMetadatas}}/>
            }
            
            { confirmDialogOpen && 
                <ConfirmRegenerateDialog open={confirmDialogOpen} 
                    handleClose={() => setConfirmDialogOpen(false)} 
                    handleConfirm={regenerateToken}/>
            }
            
            <Card sx={{ width: '100%' }}>
                <Box sx={{ p: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Box>
                        <h3>{appInfo.name}</h3>
                        <h5>{appInfo.mode}</h5>
                    </Box>
                    <Box>
                        <StatusIcon isActive={appInfo.isActive} updateStatus={updateStatus} updating={updatingStatus}/>
                    </Box>
                    
                </Box>
                <CardContent>
                    <Box sx={{padding:'.5rem', display:'flex',
                    flexDirection:'column', alignItems:'flex-start', gap:'10px' }}>
                        {appInfo?.accessGatewayMetadatas?.map(md=>(
                            <Typography key={md.id}>{md.name}: { parseInt(md.value)===-1 ? 'Unlimited' : !md.value ? 'N/A': 
                                md.key === "EXPIRATION_DATE" ? dayjs(md.value).format('MM-DD-YYYY') : md.value }</Typography>
                        )) 
                        }
                        {appInfo.isActive && <TokenVisualizer gatewayId={appInfo.id} notificate={notificationHandler}/>}
                    </Box>
                </CardContent>
                <CardActions sx={{ justifyContent:'flex-end' }} disableSpacing>
                <Stack spacing={1} direction='row' alignItems="center">
                    <LoadingButton
                        sx={{padding:'5px', minWidth:'10px'}}
                        loading={refreshing}
                        disabled={refreshing}
                        onClick={() => setConfirmDialogOpen(true)}
                        >
                        <RestartAltIcon />
                    </LoadingButton>
                    <LoadingButton 
                        sx={{padding:'5px', minWidth:'10px'}} loading={false} onClick={()=>{setEditOpen(!editOpen)}}>
                        <EditIcon />
                    </LoadingButton>
                </Stack>
                    

                </CardActions>
            </Card>
        </>
        
    )
}

export default AppCard;