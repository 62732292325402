import React, { useState } from 'react';
import { Chip } from '@mui/material'
import { useDispatch } from 'react-redux'
import api from '../../services/API'
import ALERT_ACTIONS from '../../redux/constants/alertConstants';
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CircularProgress from '@mui/material/CircularProgress';

const UnassingUserPartner = ({userId, partnerId, refresh}) => {
    const dispatch = useDispatch()
    const [unassignLoading, setUnassignLoading] = useState(false)
    const fireAlert = (message, severity = 'success') => {
        dispatch({ type: ALERT_ACTIONS.FIRE_ALERT, payload: { message: message, severity: severity } })
    }

    const handleUnassign = async () => {
        try {
            setUnassignLoading(true)
            const { data } = await api.User().unassingUserPartner(userId, partnerId, localStorage.getItem('idToken'))
            if(data.status === 'fail') throw new Error(data.message)
            fireAlert(data.message)
        } catch (error) {
            fireAlert(error.message)
        } finally {
            setUnassignLoading(false)
            refresh()
        }
    }

    return(
        <>
            <Chip label="Unassign" 
                color="error"
                size="small"
                disabled={unassignLoading}
                onClick={handleUnassign}
                onDelete={handleUnassign}
                deleteIcon={
                    unassignLoading ?
                    <CircularProgress size={13}/> : <HighlightOffIcon/>
                }/>
        </>
    )
}
export default UnassingUserPartner