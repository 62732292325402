import api from '../../services/API'
import ACTION_TYPES from '../constants/userConstants'

export const create_User = (values, token, partnerId, onError, onSuccess) => dispatch => {
  api.User().createUser(values, token, partnerId)
    .then(response => {
      onSuccess(response.data)
    })
    .catch(err => {
      onError(err)
    })
}

export const fetch_users = (token, limit, page, active, direction, column, filter, onError, onSuccess) => dispatch => {
  api.User().fetchUsers(token, limit, page, active, direction, column, filter)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.FETCH_USERS,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const fetch_users_active = (token, limit, page, active, onError, onSuccess) => dispatch => {
  api.User().fetchAllActiveUsers(token, limit, page, active)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.FETCH_USERS_ACTIVE,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}


export const fetch_users_by_id = (userId, token, onError, onSuccess) => dispatch => {
  api.User().fetchUserById(userId, token)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.FETCH_USERS_BY_ID,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const put_users_by_id = (userId, values, token, onError, onSuccess) => dispatch => {
  api.User().putUserById(userId, values, token)
    .then(response => {
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const delete_users_by_id = (userId, token, onError, onSuccess) => dispatch => {
  api.User().deleteUserById(userId, token)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.DELETE_USER_BY_ID,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const set_status_user = (userId, status, token, onError, onSuccess) => dispatch => {
  api.User().setStatusUser(userId, status, token)
    .then(response => {
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const fetch_users_by_partnerid = (partnerId, limit, page, active, filter, direction, column, onError, onSuccess) => dispatch => {
  api.User().fetchUsersByPartnerId(partnerId, limit, page, active, filter, direction, column)
    .then(response => {
      dispatch({
        type: ACTION_TYPES.FETCH_USERS_BY_PARTNERID,
        payload: response.data
      })
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const assing_user_partner = (userId, partnerId, token, onError, onSuccess) => dispatch => {
  api.User().assingUserPartner(userId, partnerId, token)
    .then(response => {
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}

export const unassing_user_partner = (userId, partnerId, token, onError, onSuccess) => dispatch => {
  api.User().unassingUserPartner(userId, partnerId, token)
    .then(response => {
      onSuccess(response.data)
    })
    .catch(err => onError(err))
}
